export const ModalSelectAssistantsI18n = {
  "pt-BR": {
    title:"Selecionar Assistente",
    tabs: {
      aizzy:"Aizzy App",
      comminuty:"Comunidade",
      my:"Meus Assistentes",
    },
  },
  "en-US": {
    title:"Select Assistant",
    tabs: {
      aizzy:"Aizzy App",
      comminuty:"Community",
      my:"My Assistants",
    },
  },
  "es-ES": {
    title:"Seleccionar Asistente",
    tabs: {
      aizzy:"Aizzy App",
      comminuty:"Comunidad",
      my:"Mis Asistentes",
    },
  },
};