import { useNavigate } from "react-router-dom";
import useTranslation from "./useTranslation";
import { NotificationI18n } from "../components/Add-ons/Notifications.i18n";
import { QUERY_KEYS } from "../libs/react-query";
import { toast } from "sonner";
import { useGetIsLogged } from "./smallHooks";
import { useQuery } from "react-query";
import { getUserCredits, getUserSubscription } from "../api/creditPayments";

const useUserCredits = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(NotificationI18n);

  const isLogged = useGetIsLogged();

  const { data: credits, isLoading: isLoadingCredits } = useQuery(
    QUERY_KEYS.USER_CREDITS,
    getUserCredits,
    {
      enabled: isLogged,
      refetchOnWindowFocus: false,
    }
  );

  const { data: subscription, isLoading: isLoadingSubscription } = useQuery(
    QUERY_KEYS.USER_SUBSCRIPTION,
    getUserSubscription,
    {
      enabled: isLogged,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const hasSufficientCredits = () => {
    const isActiveSubscription =
      subscription &&
      subscription.current_period_end > new Date().toISOString();

    if (isActiveSubscription) {
      return;
    }

    if (!credits?.amount || credits.amount <= 0) {
      toast(t("credits.noCredits"), {
        action: {
          label: t("credits.button.buy"),
          onClick: () => navigate("/settings?tab=credits"),
        },
        className: "toast-no-creditis",
      });
      return false;
    }

    if (credits.amount < 1) {
      toast(t("credits.fewCredits"), {
        action: {
          label: t("credits.button.buy"),
          onClick: () => navigate("/settings?tab=credits"),
        },
        className: "toast-no-creditis",
      });
      return false;
    }

    return true;
  };

  const hasSubscriptionActive = () => {
    const isActive =
      subscription &&
      subscription.current_period_end > new Date().toISOString();

    return isActive;
  };

  return {
    isLoadingCredits,
    credits,
    subscription,
    isLoadingSubscription,
    hasSufficientCredits,
    hasSubscriptionActive,
  };
};

export default useUserCredits;
