import ButtonType1 from "../../Buttons/ButtonType1";
import { getUser } from "../../../services/userHelper";
import { showSuccessNotification } from "../../../services/notifications";
import useTranslation from "../../../hooks/useTranslation";
import { PageSettingsI18n } from "./PageSettings.i18n";

const AffiliationSettings = () => {
  const user = getUser();
  const { t } = useTranslation(PageSettingsI18n);

  return (
    <div className="d-flex flex-column affiliation-settings">
      <div className="d-flex flex-column gap-3">
        <h4>{t("affiliation.affiliateProgram.title")}</h4>
        <div
          className="d-flex flex-column"
          style={{
            padding: 20,
            backgroundColor: "#F2F4F7",
            borderRadius: 16,
            gap: 32,
          }}
        >
          <div className="d-flex flex-column gap-3">
            <h4>🎉 {t("affiliation.affiliateProgram.subtitle")}</h4>
            <p>{t("affiliation.affiliateProgram.description")}</p>
            <span>{t("affiliation.affiliateProgram.question")}</span>
            <ul>
              <li>
                <div>1</div>
                <p>{t("affiliation.affiliateProgram.stepOne")}</p>
              </li>
              <li>
                <div>2</div>
                <p>{t("affiliation.affiliateProgram.stepTwo")}</p>
              </li>
              <li>
                <div>3</div>
                <p>{t("affiliation.affiliateProgram.stepThree")}</p>
              </li>
            </ul>
          </div>
          <div>
            <ButtonType1
              variation="primary"
              text={t("affiliation.affiliateProgram.button")}
              props={{
                style: { padding: "10px 16px", borderRadius: 10 },
                onClick: () =>
                  navigator.clipboard
                    .writeText(
                      `https://aizzy.ai?referral=${user?.user_metadata?.affiliate?.userNanoId}`
                    )
                    .then(() => showSuccessNotification("Link copied")),
              }}
            />
          </div>
          <div>
            <p className="font-size-12">
              {t("affiliation.affiliateProgram.terms")}
            </p>
          </div>
        </div>
      </div>
      <div>
        <h4>{t("taximeter.title")}</h4>
        <div className="d-flex flex-column gap-3" style={{ padding: 20 }}>
          <h4>🎉 {t("taximeter.subtitle")}</h4>
          <p>{t("taximeter.description")}</p>
        </div>
      </div>
    </div>
  );
};

export default AffiliationSettings;
