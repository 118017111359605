import { IconRobotFace } from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";
import ButtonType1 from "../Buttons/ButtonType1";
import { PageAssistantsI18n } from "../Pages/PageAssistants/HeaderPageAssistants/HeaderPageAssistants.i18n";
import useTranslation from "../../hooks/useTranslation";

const CardCreateAssistant = ({ onClick }) => {
  const { t } = useTranslation(PageAssistantsI18n);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
      <IconRobotFace
        size={48}
        stroke={1.2}
        style={{
          border: `1px solid ${COLORS.gray300}`,
          padding: 10,
          borderRadius: 10,
        }}
      />
      <div className="d-flex flex-column gap-1">
        <p className="m-0 font-size-13 text-center">
          {t("tabs.my.noAssistants")}
        </p>
        <p className="m-0 font-size-13 text-center">{t("tabs.my.create")}</p>
      </div>
      <ButtonType1
        text={t("publish")}
        props={{
          onClick: onClick,
          style: { borderRadius: "8px", width: "200px", padding: "8px 0" },
        }}
      />
    </div>
  );
};

export default CardCreateAssistant;
