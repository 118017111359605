// import { InviteDataTypes } from "../components/ModalInviteMember";
// import { GetChatUserConversationsChannelsParams } from "../pages/Images/types";
import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const channelExport = ({ channelId, messages }) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/channel/export", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getBearerToken(),
    },
    body: JSON.stringify({
      channelId,
      messages,
    }),
  })
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "aizzy_chat_exported.pdf";
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error("Error:", error);
    });
};

const getChatTopicChannels = payload => {
  return api.get(
    `${process.env.REACT_APP_API_URL}/api/channel/topic/${payload._id}`
  );
};

const getChannelsAndDocuments = () => {
  return api.get(process.env.REACT_APP_API_URL + "/api/channel");
};

const getDocuments = () => {
  return api.get(url.GET_DOCUMENTS);
};

const getDirectMessages = () => {
  return api.get(url.GET_DIRECT_MESSAGES);
};

const getChannelUrls = channelId => {
  return api.get(
    process.env.REACT_APP_API_URL + "/api/channel/" + channelId + "/urls"
  );
};

const createChannel = (data: object) => {
  return api.create(process.env.REACT_APP_API_URL + "/api/channel", data);
};

const createTopicChannel = (data: object) => {
  return api.create(
    process.env.REACT_APP_API_URL + "/api/channel/action",
    data
  );
};

const getChatUserConversationsDocuments = ({
  page = 0,
  size = 10,
  channelId,
}: any) => {
  return api.get(
    `${process.env.REACT_APP_API_URL}/api/chat/documents/${page}/${size}/${channelId}`
  );
};

// const getChatUserConversationsChannels = ({
//   page = 0,
//   size = 10,
//   assistantId,
//   channelId,
// }: GetChatUserConversationsChannelsParams) => {
//   return api.get(
//     `${process.env.REACT_APP_API_URL}/api/chat/channels/${page}/${size}/${assistantId}/${channelId}`
//   );
// };

// const getChatUserConversationsChannels = async ({
//   page = 0,
//   size = 10,
//   assistantId,
//   channelId,
// }: GetChatUserConversationsChannelsParams) => {
//   try {
//     const response = await api.get(
//       `${process.env.REACT_APP_API_URL}/api/chat/channels/${page}/${size}/${assistantId}/${channelId}`
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching chat conversations:', error);
//     throw error;
//   }
// };

const getChatUserConversationsChannelsTopic = ({
  page = 0,
  size = 10,
  assistantId,
  channelId,
}: any) => {
  return api.get(
    `${process.env.REACT_APP_API_URL}/api/channels-topic/${page}/${size}/${assistantId}/${channelId}`
  );
};

const getBearerToken = () => {
  return JSON.parse(localStorage.getItem("sb.token") || "")?.access_token;
};

const receiveMessage = (id: string | number) => {
  return api.update(url.RECEIVE_MESSAGE + "/" + id, { params: { id } });
};

const readMessage = (id: string | number) => {
  return api.update(url.READ_MESSAGE + "/" + id, { params: { id } });
};

const deleteMessage = (userId: number | string, messageId: number | string) => {
  return api.delete(url.DELETE_MESSAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId },
  });
};

const forwardMessage = (data: object) => {
  return api.create(url.FORWARD_MESSAGE, data);
};

const deleteUserMessages = (userId: number | string) => {
  return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
    params: { userId },
  });
};

/*
archive
*/

const readConversation = (id: string | number) => {
  return api.update(url.READ_CONVERSATION + "/" + id, { params: { id } });
};

const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

const deleteAssistantMessage = (chatId: string | number) => {
  return api.delete(process.env.REACT_APP_API_URL + "/api/chat/" + chatId);
};

const createDocumentEmbedding = (data: object) => {
  return api.create(process.env.REACT_APP_API_URL + "/api/embeddings", data);
};

const createVideoEmbedding = (data: object) => {
  return api.create(
    process.env.REACT_APP_API_URL + "/api/embeddings/video-channel",
    data
  );
};

const getChannelJobStatus = (channelId: string | number) => {
  return api.get(
    process.env.REACT_APP_API_URL +
      "/api/embeddings/job/status/user/" +
      channelId
  );
};

const getJobStatus = (jobId: string | number) => {
  return api.get(
    process.env.REACT_APP_API_URL + "/api/embeddings/job/status/" + jobId
  );
};

const deleteJob = (jobId: string | number) => {
  return api.delete(
    process.env.REACT_APP_API_URL + "/api/embeddings/job/" + jobId
  );
};

const deleteAttachment = (jobId: string | number) => {
  return api.delete(
    process.env.REACT_APP_API_URL + "/api/embeddings/job/" + jobId
  );
};

const deleteChannel = (channelId: string | number) => {
  return api.delete(
    process.env.REACT_APP_API_URL + "/api/channel/" + channelId
  );
};

const deleteGalleryImage = (chatId: string | number) => {
  return api.delete(process.env.REACT_APP_API_URL + "/api/chat/" + chatId);
};

const clearChannel = (channelId: string | number) => {
  return api.delete(
    process.env.REACT_APP_API_URL + "/api/channel/clear/" + channelId
  );
};

const renameChannel = data => {
  return api.update(process.env.REACT_APP_API_URL + "/api/channel", data);
};

// const inviteMemberToChannel = (data: InviteDataTypes) => {
//   return api.create(
//     process.env.REACT_APP_API_URL + "/api/channel/member",
//     data
//   );
// };

const leaveSharedChannel = (channelId: string) => {
  return api.delete(
    process.env.REACT_APP_API_URL + "/api/channel/member/" + channelId
  );
};

const createProfileImageForAssistant = async (data: any) => {
  // TODO - change this to use the correct api client
  return fetch("/api/chat/stream", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getBearerToken(),
    },
    body: JSON.stringify({
      message:
        "/create head shot, profile image, organic painting, matte painting, bold shapes, hard edges, app icon, trending on artstation, by sachin teng, artgerm, rossdraws",
      assistantId: data.assistantId,
      channelId: data.channelId,
    }),
  });
};

const deleteAllChats = () => {
  return api.delete(process.env.REACT_APP_API_URL + "/api/channel/delete/all");
};

const getModels = async () => {
  return api.get(`${process.env.REACT_APP_CHAT_API_URL}/models`);
};

export {
  getChannelUrls,
  clearChannel,
  deleteChannel,
  channelExport,
  renameChannel,
  getChannelsAndDocuments,
  getDocuments,
  getDirectMessages,
  createChannel,
  createTopicChannel,
  getChatUserConversationsDocuments,
  // getChatUserConversationsChannels,
  getChatUserConversationsChannelsTopic,
  receiveMessage,
  readMessage,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  readConversation,
  deleteImage,
  createDocumentEmbedding,
  createVideoEmbedding,
  getChatTopicChannels,
  getChannelJobStatus,
  getJobStatus,
  deleteJob,
  deleteGalleryImage,
  // inviteMemberToChannel,
  leaveSharedChannel,
  createProfileImageForAssistant,
  deleteAllChats,
  deleteAssistantMessage,
  deleteAttachment,
  getModels,
};
