import { BaseService } from "./base";

export const storeNames = {
  users: "users",
  assistants: "assistants",
  chats: "chats",
  documents: "documents",
};

interface ICompressedDocument {
  id: string;
  document: {
    postgresId: string;
    content: string;
  }[];
  active: boolean;
}

export class DocumentRepository extends BaseService {
  constructor() {
    super(storeNames.documents);
  }

  async saveDocument(documentId, document) {
    try {
      const exists = await this.get(documentId);

      if (exists) {
        return null;
      }

      return await this.db
        .table(this.storeName)
        .add({ id: documentId, document: document, active: true });
    } catch (error) {
      console.error(`Error saving document in ${this.storeName}:`, error);
      throw error;
    }
  }

  async hasActiveDocumentByChannelAndUser(
    channelId: string,
    userId: string
  ): Promise<boolean> {
    try {
      const documents: ICompressedDocument[] = await this.db
        .table(this.storeName)
        .toArray();

      const activeDocumentExists = documents.some(document => {
        const [docChannelId, docUserId] = document.id.split("_");
        return (
          docChannelId === channelId &&
          docUserId === userId &&
          document.active === true
        );
      });

      return activeDocumentExists;
    } catch (error: any) {
      console.error(
        `Erro ao verificar documento ativo pelo channelId e userId em ${this.storeName}:`,
        error?.message
      );
      throw error;
    }
  }

  async updateDocumentActiveStatus(documentId, isActive: boolean) {
    try {
      const data = await this.get(documentId);

      if (!data) {
        console.warn(`Document with ID ${documentId} not found.`);
        return null;
      }

      return await this.db.table(this.storeName).put({
        id: documentId,
        active: isActive,
        document: data.document,
      });
    } catch (error) {
      console.error(
        `Error updating document active status in ${this.storeName}:`,
        error
      );
      throw error;
    }
  }

  async getCompressedDocumentsByPostgresIds(postgresIds: string[]) {
    try {
      const documents: ICompressedDocument[] = await this.db
        .table(this.storeName)
        .toArray();

      const contents = documents
        .filter(document => document.active)
        .flatMap(document => document.document)
        .filter(data => postgresIds.includes(data?.postgresId))
        .map(data => ({
          id: data.postgresId,
          content: data.content,
        }));

      return contents;
    } catch (error: any) {
      console.error(
        `Erro ao buscar documentos por ids do Postgres em ${this.storeName}:`,
        error?.message
      );
      throw error;
    }
  }

  async deleteDocumentById(id: string) {
    try {
      await this.db.table(this.storeName).delete(id);
    } catch (error) {
      console.error(
        `Error deleting document with id ${id} from ${this.storeName}:`,
        error
      );
      throw error;
    }
  }
}

export function makeDocumentId(
  channelId: string,
  userId: string,
  jobId: string
) {
  return `${channelId}_${userId}_${jobId}`;
}
