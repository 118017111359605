import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";
import i18n from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { configureStore } from "./redux/store";
import { getBrowserLanguage } from "./utils/helpers";
import { CaptchaProvider } from "./contexts/CaptchaContext";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./libs/react-query";

import "./globals.css";
import { getAndUpdateUser } from "./services/userHelper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_ANON_KEY as string,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
      storageKey: "sb.token",
    },
  }
);

i18n.init({
  lng: getBrowserLanguage(),
});

supabaseClient.auth
  .getSession()
  .then(async (session: any) => {
    const userData = session?.data?.session?.user;
    const userLanguage = userData?.user_metadata?.language;

    const lng = userLanguage ?? getBrowserLanguage();
    i18n.changeLanguage(lng);

    const userLS = localStorage.getItem("user");

    if (!userLS && userData?.id) {
      await getAndUpdateUser();
    }
  })
  .catch((error: any) => {
    console.error("Error retrieving session:", error);
    i18n.changeLanguage(getBrowserLanguage());
  });

root.render(
  <I18nextProvider i18n={i18n}>
    <SessionContextProvider supabaseClient={supabaseClient}>
      <QueryClientProvider client={queryClient}>
        <Provider store={configureStore({})}>
          <CaptchaProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CaptchaProvider>
        </Provider>
      </QueryClientProvider>
    </SessionContextProvider>
  </I18nextProvider>
);
