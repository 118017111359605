import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import COLORS from "../../utils/constants/colors";
import { useState } from "react";

const CardPayment = ({
  value,
  valueAmount,
  setValueAmount,
  handleInputValue,
  method,
  titleMethod,
  methodIcon,
  selectedPayment,
  handleChange,
  handleBlur,
  checked,
}) => {
  const state = useSelector(state => state);
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;
  const [onFocus, setFocus] = useState(false);

  const handleDivClick = () => {
    handleChange({ target: { value: selectedPayment, checked: !checked } });
  };

  return (
    <>
      {value && (
        <div
          className="w-100 d-flex align-items-center"
          style={{
            maxWidth: 800,
            height: isMobile ? 54 : 64,
            borderRadius: 12,
            border: onFocus ? `1px solid ${COLORS.gray400}` : `1px solid ${COLORS.gray300}`,
            padding: "0 20px",
          }}
        >
          <div className="w-100 d-flex align-items-center gap-2" style={{ color: COLORS.gray700 }}>
            <span className={`font-size-${isMobile ? 14 : 18}`}>$</span>
            <input
              value={valueAmount}
              style={{ border: "none", fontSize: isMobile ? 14 : 18 }}
              onChange={handleInputValue}
              onFocus={() => setValueAmount("")}
              onBlur={() => {setFocus(false); handleBlur()}}
              min={10}
              onFocusCapture={() => setFocus(true)}
            />
          </div>
        </div>
      )}

      {method && (
        <div
          onClick={handleDivClick}
          className="w-100 d-flex align-items-center card-payment-method"
          style={{
            maxWidth: 800,
            height: isMobile ? 54 : 64,
            borderRadius: 12,
            border: checked ? `1px solid ${COLORS.gray400}` : `1px solid ${COLORS.gray300}`,
            padding: "0 20px",
            cursor: "pointer",
          }}
        >
          <div className="w-100 d-flex align-items-center justify-content-between gap-2">
            <div className="d-flex align-items-center gap-2">
              <div
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  border: checked ? `5px solid ${COLORS.gray800}` : `1px solid ${COLORS.gray300}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
              </div>
              <span className={`font-size-${isMobile ? 14 : 18}`}>{titleMethod}</span>
            </div>
            <div className="d-flex align-items-center gap-2">{methodIcon}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardPayment;
