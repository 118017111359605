import { IconUser, IconX } from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import CardPayment from "../Cards/CardPayment";
import pix from "../../assets/images/icons-new-layout/pix.svg";
import visa from "../../assets/images/icons-new-layout/visa.svg";
import mastercard from "../../assets/images/icons-new-layout/mastercard.svg";
import metamask from "../../assets/images/icons-new-layout/metamask-payment.svg";
import preview from "../../assets/images/icons-new-layout/preview-landing.svg";
import { PAYMENT_METHODS } from "../../utils/constants/paymentMethods";
import COLORS from "../../utils/constants/colors";
import CardOrderSummary from "../Cards/CardOrderSummary";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import imgProfile from "../../assets/images/icons-new-layout/avatar-profile.svg";
import { useEffect } from "react";
import useTranslation from "../../hooks/useTranslation";
import { ModalCreditsI18n } from "./ModalCredits.i18n";

function ModalPayment({
  openModal,
  setOpenModal,
  valueAmount,
  setValueAmount,
  handleClick,
  isLoading,
  dataUser,
  t2,
  handleBlur,
  handleChange,
  handleInputValue,
  selectedPayment,
  aizAmount,
}) {
  const state = useSelector(state => state);
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;
  const { t } = useTranslation(ModalCreditsI18n);


  const method = [
    {
      title: "Pix",
      icon: <img src={pix} alt="" width={isMobile ? 24 : 32} />,
      method: PAYMENT_METHODS.PIX,
    },
    {
      title: t("method.card"),
      icon: (
        <>
          <img src={visa} alt="" width={isMobile ? 24 : 32} />
          <img src={mastercard} alt="" width={isMobile ? 24 : 32} />
        </>
      ),
      method: PAYMENT_METHODS.CARD,
    },
    {
      title: "Metamask",
      icon: <img src={metamask} alt="" width={isMobile ? 24 : 32} />,
      method: PAYMENT_METHODS.AIZ,
    },
  ];

  useEffect(() => {
    if (openModal) {
      handleChange(null);
    }
  }, [openModal]);

  return (
    <ModalGlobal
      onClick={() => {
        setOpenModal(false);
        setValueAmount(10);
      }}
      open={openModal}
      showBtnClose={false}
      className={"gap-4 align-items-end"}
      classNameChildren={
        `${isMobile && "p-3"} animate__animated animate__fadeInDown animate__faster mb-0 mb-sm-3 modal-payment d-flex flex-column gap-3 justify-content-between`
      }
    >
      <div className="d-flex flex-column gap-5 flex-grow-1">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex gap-3 align-items-center">
            <h4 className="m-0 font-size-24">{t2("modals.credits.label")}</h4>
          </div>
          <button className="btn-icon-header"
            onClick={() => {
              setOpenModal(false);
              setValueAmount(10);
              handleChange(null);
            }}>
            <IconX
              stroke={1.5}
              style={{ width: 16, height: 16 }}
            />
          </button>
        </div>

        <div
          className="d-grid gap-5"
          style={{
            height: "calc(100% - 80px)",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
          }}
        >
          <div className="w-100 d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-2">
              <h5>{t2("modals.credits.value.title")}</h5>
              <CardPayment
                value={true}
                valueAmount={valueAmount}
                setValueAmount={setValueAmount}
                handleInputValue={handleInputValue}
                handleBlur={handleBlur}
              />
              {selectedPayment === PAYMENT_METHODS.AIZ && valueAmount > 0 && (
                <div className="d-flex flex-column gap-2">
                  <span>
                    {t2("modals.credits.method.aiz")}{" "}
                    <span className="fw-semibold">
                      {aizAmount.toFixed(4)} AIZ
                    </span>{" "}
                    {t2("modals.credits.method.aizContinue")}
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex flex-column gap-3 justify-content-between h-100">
              <div className="d-flex flex-column gap-3">
                <h5>{t2("modals.credits.method.title")}</h5>
                {method.map(item => (
                  <CardPayment
                    key={item.title}
                    titleMethod={item.title}
                    methodIcon={item.icon}
                    method={true}
                    valueAmount={valueAmount}
                    setValueAmount={setValueAmount}
                    handleInputValue={handleInputValue}
                    handleChange={() => {
                      handleChange(item.method);
                    }}
                    selectedPayment={selectedPayment === item.method}
                    checked={selectedPayment === item.method}
                  />
                ))}
              </div>
              {!isMobile && (
                <div className="d-flex align-items-center gap-2">
                  <span>{t("account")}</span>
                  {dataUser?.picture ? (
                    <img
                      src={dataUser?.picture}
                      alt=""
                      className="profile-user"
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img src={imgProfile} alt="" />
                  )}
                  <span>{dataUser?.name}</span>
                </div>
              )}
            </div>
          </div>

          {selectedPayment ? (
            <div
              className="w-100 h-100 d-flex align-items-start justify-content-center"
              style={{
                background: COLORS.gray100,
                borderRadius: 24,
                padding: selectedPayment ? (isMobile ? 22 : 48) : 0,
                marginBottom: isMobile ? 20 : 0,
              }}
            >
              <div className="d-flex flex-column" style={{ gap: 32 }}>
                <h4 className="m-0 font-size-18" style={{ color: "black" }}>
                  {t("orderSummary")}
                </h4>
                <CardOrderSummary
                  handleClick={handleClick}
                  isLoading={isLoading}
                  inputValue={valueAmount}
                  selectedPayment={selectedPayment}
                  aizAmount={aizAmount}
                  t2={t2}
                />
              </div>
            </div>
          ) : (
            <img src={preview} alt="" width={"100%"} />
          )}
        </div>
      </div>
    </ModalGlobal>
  );
}

export default ModalPayment;
