import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { ModalSelectAssistantsI18n } from "./ModalSelectAssistant.i18n";

const TabsModalAssistants = ({ aizzy, community, myAssistants }) => {
  const [activeTab, setActiveTab] = useState("aizzy");
  const { t } = useTranslation(ModalSelectAssistantsI18n);

  const tabs = [
    {
      id: "aizzy",
      label: t("tabs.aizzy"),
      content: aizzy,
    },
    {
      id: "commmunity",
      label: t("tabs.comminuty"),
      content: community,
    },
    {
      id: "myAssistants",
      label: t("tabs.my"),
      content: myAssistants,
    },
    // {
    //   id: "copywriting",
    //   label: "Copywriting",
    //   content: <h2>Copywriting</h2>,
    // },
    // {
    //   id: "research",
    //   label: "Pesquisa",
    //   content: <h2>Pesquisa</h2>,
    // },
    // {
    //   id: "programming",
    //   label: "Programação",
    //   content: <h2>Programação</h2>,
    // },
    // {
    //   id: "socialMedia",
    //   label: "Redes Sociais",
    //   content: <h2>Redes Sociais</h2>,
    // },
    // {
    //   id: "project",
    //   label: "Projetos",
    //   content: <h2>Projetos</h2>,
    // },
  ];

  return (
    <div>
      <div className="d-flex mb-4 gap-2">
        {tabs.map(tab => (
          <button
            type="button"
            key={tab.id}
            className={`tab-button one-line ${
              activeTab === tab.id ? "active" : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            <i className={tab.icon} />
            {tab.label}
          </button>
        ))}
      </div>
      {tabs.map(
        tab => tab.id === activeTab && <div key={tab.id}>{tab.content}</div>
      )}
    </div>
  );
};

export default TabsModalAssistants;
