import { useEffect, useRef, useState } from "react";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import DropdownTools from "./DropdownTools";
import COLORS from "../../../../../utils/constants/colors";

const TitleTopBar = ({ configsMainChat }) => {
  const [openTools, setOpenDropdownTools] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = () => {
    setOpenDropdownTools(prevOpenTools => !prevOpenTools);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownTools(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div id="title-tools-top-bar">
      <span
        className="one-line"
        style={{
          color: openTools ? COLORS.gray700 : COLORS.gray500,
          width: "100%",
          maxWidth: 200,
        }}
      >
        {configsMainChat?.chatData?.channelName}
      </span>
      {configsMainChat?.chatData && (
        <button aria-label="Open Tools">
          {openTools ? (
            <IconChevronUp
              size={16}
              onClick={handleClick}
              style={{ color: COLORS.gray700 }}
            />
          ) : (
            <IconChevronDown size={16} onClick={handleClick} />
          )}
          <div
            ref={dropdownRef}
            className={openTools ? "dropdown-tools show" : "dropdown-tools"}
          >
            <DropdownTools
              onClick={() => setOpenDropdownTools(false)}
              channel={configsMainChat}
              setLoading={() => {}}
            />
          </div>
        </button>
      )}
    </div>
  );
};

export default TitleTopBar;
