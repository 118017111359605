/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { IconCheck, IconChevronLeft, IconChevronRight, IconLoader2 } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { QUERY_KEYS } from "../../../libs/react-query";
import { getFreeCommunityAssistants } from "../../../api/assistants";
import COLORS from "../../../utils/constants/colors";
import { setConfigsMainChat } from "../../../redux/general/action";
import { getCurrentUserLanguage } from "../../../services/userHelper";
import { showRealoadNotification } from "../../../services/notifications";

const ListCommunityAssistantsModal = ({
  activeAssistant,
  setActiveAssistant,
  state,
  handleClose,
}) => {
  let showError = false;
  const dispatch = useDispatch();
  const [setAssistant] = useState(
    state?.configsGeneralReducer?.configsMainChat?.[KEYS_MAIN_CHAT.assistant]
      ?.name
  );
  const [page, setPage] = useState(0);

  const userLanguage = getCurrentUserLanguage()

  // Configuração do useQuery
  const { data, isLoading, isFetching, error } = useQuery(
    [QUERY_KEYS.COMMUNITY_ASSISTANTS, page],
    () =>
      getFreeCommunityAssistants({
        pageNumber: page,
        language: userLanguage
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }
  );

  const assistants = data?.assistants || [];

  
  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
 }, [error]);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-between">
        <button
          className="fw-semibold d-flex align-items-center justify-content-center gap-1"
          style={{
            fontSize: 12,
            color: COLORS.gray500,
          }}
          type="button"
          disabled={page === 0}
          onClick={() => setPage(prev => Math.max(prev - 1, 0))}
        >
          <IconChevronLeft size={16} />
          Previous page
        </button>
        <button
          className="fw-semibold d-flex align-items-center justify-content-center gap-1"
          style={{
            fontSize: 12,
            color: COLORS.gray500,
          }}
          type="button"
          disabled={assistants.length < 20}
          onClick={() => setPage(prev => prev + 1)}
        >
          Next page
          <IconChevronRight size={16} />
        </button>
      </div>

      <ul
        style={{
          gap: 16,
          display: "flex",
          flexDirection: "column",
          marginBottom: 16,
        }}
      >
        {assistants && assistants?.map(assistant => (
          <li
            style={{
              padding: "0 10px",
              cursor: "pointer",
              borderRadius: 8,
            }}
            key={assistant.name}
            className={`d-flex gap-3 align-items-center list-assistants-modal ${
              activeAssistant === assistant ? "active" : ""
            }`}
            onClick={() => {
              handleClose();
              dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant));
              setAssistant(assistant.name);
              setActiveAssistant(assistant);
            }}
          >
            <img
              src={assistant.profileImage}
              alt={assistant.name}
              loading="lazy"
              width={24}
              height={24}
              style={{ borderRadius: "50%" }}
            />
            <span>{assistant.role}</span>
            {activeAssistant.name === assistant.name && (
              <IconCheck stroke={1.5} size={14} className="active-icon" />
            )}
          </li>
        ))}
      </ul>

      <div className="d-flex align-items-center justify-content-center mt-4">
        {isLoading || isFetching ? (
          <IconLoader2 className="spin" size={24} />
        ) : null}
      </div>
    </div>
  );
};

export default ListCommunityAssistantsModal;
