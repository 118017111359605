// components
import { useDispatch } from "react-redux";
import { Toaster } from "sonner";
import SideMenuType2 from "../../components/Pages/PageChat/SideMenuType2/SideMenuType2";
import { useEffect } from "react";
import { setWindowWidth } from "../../redux/general/action";

interface IndexProps {
  children: any;
}
const Index = (props: IndexProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setWindowWidth(window.innerWidth));
    window.addEventListener("resize", () => {
      dispatch(setWindowWidth(window.innerWidth));
    });
  }, []);

  return (
    <div className="d-flex">
      {/* {(user || userAuth) && true && (
        <SideMenu onChangeLayoutMode={onChangeLayoutMode} />
      )} */}
      <SideMenuType2 />
      {props.children}
      <Toaster theme={"light"} position="top-center" duration={3000} />

      {/* <ModalAlertWhatsapp /> */}
    </div>
  );
};

export default Index;
