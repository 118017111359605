export const ModalAttachFileI18n = {
  "pt-BR": {
    title: "Anexar fonte",
    drag: "Arraste e solte documentos e arquivos",
    buttons: {
      select: "Selecionar",
      addLink: "Adicionar link",
    },
    supportedFiles: {
      p1: "Você pode fazer o upload",
      p2: "texto",
      p3: "dados",
      p4: "código",
      p5: "e",
      p6: "media",
      tooltip:
        "Arquivos de imagem suportados: JPEG (.jpg, .jpeg), PNG (.png), GIF (.gif), SVG (.svg), BMP (.bmp), TIFF (.tiff)",
    },
    data: "Dados",
    manage: "Gerenciar Uso",
    dataManage: {
      title: "Gerenciar Dados",
      description:
        "A IA usa os dados do documento para treinar continuamente o chatbot e fornecer respostas específicas.",
      status: "Status",
    },
  },
  "en-US": {
    title: "Attach source",
    drag: "Drag & drop documents and files",
    buttons: {
      select: "Select",
      addLink: "Add link",
    },
    supportedFiles: {
      p1: "You can upload",
      p2: "text",
      p3: "data",
      p4: "code",
      p5: "and",
      p6: "media",
      tooltip:
        "Supported image files: JPEG (.jpg, .jpeg), PNG (.png), GIF (.gif), SVG (.svg), BMP (.bmp), TIFF (.tiff)",
    },
    data: "Data",
    manage: "Manage Usage",
    dataManage: {
      title: "Manage Data",
      description:
        "The AI uses the document’s data to continuously train the chatbot and deliver content-specific responses.",
      status: "Status",
    },
  },
  "es-ES": {
    title: "Adjuntar fuente",
    drag: "Arrastrar y soltar documentos y archivos",
    buttons: {
      select: "Seleccionar",
      addLink: "Agregar enlace",
    },
    supportedFiles: {
      p1: "Puedes subir",
      p2: "texto",
      p3: "datos",
      p4: "código",
      p5: "y",
      p6: "medios",
      tooltip:
        "Archivos de imagen admitidos: JPEG (.jpg, .jpeg), PNG (.png), GIF (.gif), SVG (.svg), BMP (.bmp), TIFF (.tiff)",
    },
    data: "Datos",
    manage: "Gestionar Uso",
    dataManage: {
      title: "Gestionar Datos",
      description:
        "La IA utiliza los datos del documento para entrenar continuamente al chatbot y proporcionar respuestas específicas.",
      status: "Estado",
    },
  },
};
