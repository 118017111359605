import { createContext, ReactNode, useContext, useState } from "react";

interface CaptchaContextProps {
  showCaptcha: boolean;
  captchaToken: string | null;
  onSubmitChat: () => void;
  handleVerificationSuccess: (token: string, ekey: string) => void;
}

const CaptchaContext = createContext({} as CaptchaContextProps);

interface CaptchaProviderProps {
  children: ReactNode;
}

export function CaptchaProvider({ children }: CaptchaProviderProps) {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [lastAttemptTimeInMilliseconds, setLastAttemptTimeInMilliseconds] =
    useState<number>(0);

  function onSubmitChat() {
    const currentTimeInMilliseconds = new Date().getTime();
    if (currentTimeInMilliseconds - lastAttemptTimeInMilliseconds < 1000) {
      setCaptchaToken(null);
      return setShowCaptcha(true);
    }

    setLastAttemptTimeInMilliseconds(new Date().getTime());
  }

  function handleVerificationSuccess(token: string, ekey: string) {
    setTimeout(() => {
      setShowCaptcha(false);
    }, 1000);

    setCaptchaToken(token);
  }

  const contextValue: CaptchaContextProps = {
    showCaptcha,
    captchaToken,
    onSubmitChat,
    handleVerificationSuccess,
  };

  return (
    <CaptchaContext.Provider value={contextValue}>
      {children}
    </CaptchaContext.Provider>
  );
}

export function useCaptcha() {
  const context = useContext(CaptchaContext);
  if (!context) {
    throw new Error("useCaptcha deve ser usado dentro de um CaptchaProvider");
  }
  return context;
}
