import ButtonType1 from "../../Buttons/ButtonType1";
import google from "../../../assets/images/icons/google.svg";
import useTranslation from "../../../hooks/useTranslation";
import { GroupLoginI18n } from "../GroupLogin.i18n";
import { RecoverPassword } from "./RecoverPassword";

const Web2Login = ({
  isRegistering,
  isResetingPassword,
  setIsResetingPassword,
  isLoading = false,
  isLoadingGoogle = false,
  email,
  handleLogin = () => {},
  handleSocialLogin = () => {},
  onChangeEmail = () => {},
  onChangePassword = () => {},
}) => {
  const { t } = useTranslation(GroupLoginI18n);

  return (
    <>
      {isResetingPassword ? (
        <RecoverPassword recoverEmail={email} t={t} />
      ) : (
        <form
          action=""
          onSubmit={e => {
            e.preventDefault();
            handleLogin();
          }}
          className="form-login-web2"
        >
          <div className="group">
            <label htmlFor="email">{t("email")}</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              onChange={onChangeEmail}
              placeholder={t("placeholderEmail")}
            />
          </div>
          <div className="group">
            <label htmlFor="password">{t("password")}</label>
            <input
              type="password"
              onChange={onChangePassword}
              name="password"
              id="password"
              required
              placeholder={t("placeholderPassword")}
            />
          </div>
          <ButtonType1
            text={isRegistering ? t("createAccount") : t("signIn")}
            isLoading={isLoading}
            variation="primary"
            props={{
              className: "btn-main-form",
              type: "submit",
            }}
          />
          <div className="d-flex align-items-center justify-content-center">
            <button type="button" onClick={() => setIsResetingPassword(true)}>
              {t("forgotPassword")}
            </button>
          </div>
          <div className={"divider"}>OR</div>
          <ButtonType1
            isLoading={isLoadingGoogle}
            icon={google}
            text={t("google")}
            variation="thirty"
            props={{
              className: "btn-socials",
              type: "button",
              onClick: handleSocialLogin,
            }}
          />
        </form>
      )}
    </>
  );
};

export default Web2Login;
