import useTranslation from "../../hooks/useTranslation";
import { showSuccessNotification } from "../../services/notifications";
import { NotificationI18n } from "../Add-ons/Notifications.i18n";

interface ButtonCopyMessageProps {
  message: string;
}

export function ButtonCopyMessage({ message }: ButtonCopyMessageProps) {
  const { t } = useTranslation(NotificationI18n);
  return (
    <button
      style={{
        backgroundColor: "transparent",
        borderStyle: "none",
        outlineStyle: "none",
        color: "inherit",
        width: 32,
        height: 32,
        marginLeft: "auto",
        marginBottom: 0,
        marginTop: 0,
        border: "1px solid #D0D5DD",
        borderRadius: 8,
      }}
      onClick={() => {
        navigator.clipboard.writeText(message);
        showSuccessNotification(t("copyMessage"));
      }}
    >
      <i className="bx bx-copy" />
    </button>
  );
}
