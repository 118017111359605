import { Select } from "antd";
import IconArrowDown from "../../../assets/images/icons-new-layout/arrow-down.svg";
import { useState } from "react";
// import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { updateAssistantData } from "../../../redux/createAssistants/action";
import { getAssistantImagesDefalut } from "../../../api/assistants";
import { IconAlertCircle, IconPlus, IconX } from "@tabler/icons-react";
import ModalImagesAvatar from "../ModalImagesAvatar";
import useTranslation from "../../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "./ModalCreateAssistants.i18n";
import Tooltip from "../../Add-ons/Tooltip";

const CreateAssistantsGeneralInfo = ({ alert }) => {
  // const typesSupported = [
  //   "jpg",
  //   "jpeg",
  //   "png",
  // ];
  //const state = useState();
  const dispatch = useDispatch();
  const assistantData = useSelector(
    state => state.assistantReducer.assistantData
  );
  const [showModal, setShowModal] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [nameCharCount, setNameCharCount] = useState(0);

  const { t } = useTranslation(ModalCreateAssistantsI18n);

  const handleSelectGeneratedImage = url => {
    setAvatar(url);
  };

  const handleNameChange = e => {
    const value = e.target.value;
    setNameCharCount(value.length);
    dispatch(updateAssistantData({ name: value }));
  };

  const handleAboutChange = e => {
    const value = e.target.value;
    dispatch(updateAssistantData({ about: value }));
  };

  const handleRoleChange = e => {
    const value = e.target.value;
    dispatch(updateAssistantData({ role: value }));
  };

  /*
  const handleCategoryChange = value => {
    dispatch(updateAssistantData({ category: value }));
  };
*/
  const handleAvatarChange = value => {
    dispatch(updateAssistantData({ avatar: value }));
  };

  const handleActiveChange = value => {
    if (value === "Sim") {
      dispatch(updateAssistantData({ enabled: true }));
    } else if (value === "Não") {
      dispatch(updateAssistantData({ enabled: false }));
    }
  };

  const selectPrivate = value => {
    if (value === "Privado") {
      dispatch(updateAssistantData({ private: true }));
    } else if (value === "Público") {
      dispatch(updateAssistantData({ private: false }));
    }
  };

  const handleGenerateImage = async () => {
    setShowModal(true);
    setLoadingImage(true);
    getAssistantImagesDefalut()
      .then(images => {
        setGeneratedImages(images);
      })
      .finally(() => setLoadingImage(false));
  };

  return (
    <div className="create-assistants-general-info">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.general.inputs.name.label")}</span>
        <div className="div-input-general-info">
          <input
            type="text"
            placeholder={t("tabs.general.inputs.name.placeholder")}
            value={assistantData?.name}
            onChange={handleNameChange}
            maxLength={"30"}
          />{" "}
          {nameCharCount === 30 && (
            <p
              className="font-size-12 mt-2 mb-0 animate__animated animate__fadeIn animate__faster"
              style={{ color: "red" }}
            >
              Maximum 30 characters.
            </p>
          )}
        </div>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-start">
        <span className="span">{t("tabs.general.inputs.avatar.label")}</span>
        <div className="select-avatar w-100 d-flex flex-column justify-content-start gap-2">
          {/* <FileUploader
            classes="upload-img"
            name="Image"
            types={typesSupported}
          /> */}
          <div
            style={{ width: 110, height: 40 }}
            className="d-flex justify-content-center align-items-center div-input-upload-avatar"
          >
            {assistantData.avatar ? (
              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <img
                  src={assistantData.avatar}
                  alt=""
                  width={24}
                  height={24}
                  style={{ borderRadius: "50%" }}
                />
                <IconX
                  style={{ cursor: "pointer" }}
                  size={12}
                  stroke={3}
                  onClick={() => {
                    dispatch(updateAssistantData({ avatar: "" }));
                  }}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center w-100">
                <input
                  type="text"
                  placeholder={
                    avatar ? "" : t("tabs.general.inputs.avatar.placeholder")
                  }
                />
                <IconPlus
                  style={{ cursor: "pointer" }}
                  size={14}
                  stroke={3}
                  onClick={() => {
                    handleGenerateImage();
                    setShowModal(true);
                  }}
                />
              </div>
            )}
          </div>
          {/* <div className="d-flex gap-2 align-items-center">
            <Checkbox style={{ height: 15 }} />
            <span className="span">Generate with AI</span>
          </div> */}
        </div>
      </div>

      <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.general.inputs.role.label")}</span>
        <input
          className="select-antd-create-assistant"
          size="large"
          value={assistantData?.role}
          onChange={handleRoleChange}
          placeholder={t("tabs.general.inputs.role.placeholder")}
        ></input>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div className="position-relative h-100 d-flex tolltip-about-create-assistant">
          <span className="span">{t("tabs.general.inputs.about.label")}</span>
          <Tooltip text={t("tabs.general.inputs.about.tooltip")}>
            <IconAlertCircle
              className="position-absolute"
              cursor={"pointer"}
              stroke={1.5}
              size={16}
              style={{ top: "-5px", right: "-20px" }}
            />
          </Tooltip>
        </div>
        <div className="div-input-general-info">
          <input
            type="text"
            placeholder={t("tabs.general.inputs.about.placeholder")}
            value={assistantData?.about}
            onChange={handleAboutChange}
          />
        </div>
      </div>
      {/* <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.general.inputs.category.label")}</span>
        <Select
          className="select-antd-create-assistant"
          suffixIcon={
            <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
          }
          size="large"
          value={
            assistantData?.category === ""
              ? "Select category"
              : assistantData?.category
          }
          onChange={handleCategoryChange}
          placeholder="Select category"
        >
          <Select.Option
            style={{ display: "flex", alignItems: "center" }}
            value="assistant"
          >
            Assistant
          </Select.Option>
        </Select>
      </div> */}
      <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.general.inputs.active.label")}</span>
        <Select
          className="select-antd-create-assistant"
          suffixIcon={
            <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
          }
          size="large"
          value={assistantData?.enabled === true ? "Sim" : "Não"}
          onChange={handleActiveChange}
        >
          <Select.Option
            style={{ display: "flex", alignItems: "center" }}
            value="Sim"
          >
            {t("tabs.general.inputs.active.placeholder.yes")}
          </Select.Option>
          <Select.Option
            style={{ display: "flex", alignItems: "center" }}
            value="Não"
          >
            {t("tabs.general.inputs.active.placeholder.no")}
          </Select.Option>
        </Select>
      </div>
      <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.general.inputs.control.label")}</span>
        <Select
          className="select-antd-create-assistant"
          suffixIcon={
            <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
          }
          size="large"
          value={
            assistantData?.private === ""
              ? "Select"
              : assistantData?.private === true
              ? "Privado"
              : "Público"
          }
          onChange={selectPrivate}
        >
          <Select.Option
            style={{ display: "flex", alignItems: "center" }}
            value="Público"
          >
            {t("tabs.general.inputs.control.placeholder.public")}
          </Select.Option>
          <Select.Option
            style={{ display: "flex", alignItems: "center" }}
            value="Privado"
          >
            {t("tabs.general.inputs.control.placeholder.private")}
          </Select.Option>
        </Select>
      </div>

      <ModalImagesAvatar
        showModal={showModal}
        generatedImages={generatedImages}
        loadingImage={loadingImage}
        onClick={() => {}}
        onSelectGeneratedImage={handleSelectGeneratedImage}
        closeModal={() => {
          setShowModal(false);
        }}
        handleAvatarChange={handleAvatarChange}
        handleGenerateImage={handleGenerateImage}
      />
    </div>
  );
};
export default CreateAssistantsGeneralInfo;
