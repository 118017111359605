export const ModalInfoAssistantsI18n = {
    "pt-BR": {
        create: "Criado por",
        productivity: "Em produtividade",
        likes: "Likes",
        more: "Mais",
        buttonChat: "Iniciar chat",
        onlyCreated: "Somente este assistente foi criado por ",
        elevate: "Eleve sua escrita com sugestões alimentadas por IA."
    },
    "en-US": {
        create: "Created by",
        productivity: "In productivity",
        likes: "Likes",
        more: "More",
        buttonChat: "Start chat",
        onlyCreated: "Only has this created assistant by ",
        elevate: "Elevate your writing with AI-powered suggestions."
    },
    "es-ES": {
        create: "Creado por",
        productivity: "En produtividad",
        likes: "Likes",
        more: "Más",
        buttonChat: "Iniciar chat",
        onlyCreated: "Solo tiene este asistente creado por ",
        elevate: "Eleva tu escritura con sugerencias alimentadas por IA."
    },
  };