/* eslint-disable no-lone-blocks */
import { IconCheck, IconChevronDown, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useGetIsLogged } from "../../../../hooks/smallHooks";
import { setConfigsMainChat, setOpenLogin } from "../../../../redux/general/action";
import TabsModalAssistants from "../../../Modals/TabsModalAssistants";
import ListCommunityAssistantsModal from "../../../Modals/ListAssistantsModal/ListCommunityAssistantsModal";
import ListAizzyAssistantsModal from "../../../Modals/ListAssistantsModal/ListAizzyAssistantsModal";
import ListMyAssistantsModal from "../../../Modals/ListAssistantsModal/ListMyAssistantsModal";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";
import ModalGlobal from "../../../Modals/ModalGlobal";
import { Hits, InstantSearch, SearchBox } from "react-instantsearch-dom";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import searchClient from "../../../../services/algolia";
import { useNavigate } from "react-router-dom";
import { useChatStore } from "../../../../state/chat";
import useTranslation from "../../../../hooks/useTranslation";
import { ModalSelectAssistantsI18n } from "../../../Modals/ModalSelectAssistant.i18n";

const ChangeAssistant = ({ state, background }) => {
  const [showModalAssistant, setShowModalAssistant] = useState(false);
  const [activeAssistant, setActiveAssistant] = useState(
    state?.configsGeneralReducer?.configsMainChat?.[KEYS_MAIN_CHAT.assistant]
  );
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const { t } = useTranslation(ModalSelectAssistantsI18n);

  const { isReceivingMessage } = useChatStore();

  const isLogged = useGetIsLogged();

  const navigate = useNavigate();

  const assistantAvatar =
    state?.configsGeneralReducer?.configsMainChat?.[KEYS_MAIN_CHAT.assistant]
      ?.profileImage;
  const assistantRole =
    state?.configsGeneralReducer?.configsMainChat?.[KEYS_MAIN_CHAT.assistant]
      ?.role;

  const mobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;

  const handleOpenAssistantModal = e => {
    e.preventDefault();
    {
      isLogged
        ? mobile
          ? navigate("/assistants")
          : setShowModalAssistant(true)
        : dispatch(setOpenLogin(true));
    }
  };

  const handleClose = e => {
    setShowModalAssistant(false);
    setSearchTerm("");
    setActiveAssistant({});
  };

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.SM;

  const SearchedAssistant = ({ hit }) => {
    const objectAssistant = {
      _id: hit.objectID,
      category: hit.category,
      enabled: hit.enabled,
      name: hit.name,
      language: hit.language,
      role: hit.role,
      about: hit.about,
      profileImage: hit.profileImage,
      userId: hit.userId,
      userName: hit.userName,
      likes: hit.likes,
      userPrice: hit.userPrice,
      defaultGreetings: hit.defaultGreetings,
      likedby: hit.likedby,
      private: hit.private,
      hired: hit.hired,
    };

    return (
      <div
        key={hit.objectID}
        style={{ padding: "0 10px", cursor: "pointer", borderRadius: 8 }}
        className={`d-flex px gap-3 align-items-center list-assistants-modal w-100 ${
          activeAssistant === hit?.name ? "active" : ""
        }`}
        onClick={() => {
          handleClose();
          dispatch(
            setConfigsMainChat(KEYS_MAIN_CHAT.assistant, objectAssistant)
          );
          setActiveAssistant(hit);
          setShowModalAssistant(false);
        }}
      >
        <img
          src={hit?.profileImage}
          alt={hit?.name}
          loading="lazy"
          width={24}
          height={24}
          style={{ borderRadius: "50%" }}
        />
        <span>{hit?.role.split(" ").slice(0, 3).join(" ")}</span>
        {activeAssistant?.name === hit?.name && (
          <IconCheck stroke={1.5} size={14} className="active-icon" />
        )}
      </div>
    );
  };

  return (
    <>
      <button
        className="change-assistant-wrapper justify-content-between"
        onClick={handleOpenAssistantModal}
        disabled={isReceivingMessage}
        type="button"
        style={{
          background: background,
          height: 40,
          borderRadius: isMobile ? 6 : 8,
        }}
      >
        <div className="d-flex align-items-center gap-2">
          <div className="wrapper-avatar">
            <img
              src={assistantAvatar}
              alt={assistantRole}
              loading="lazy"
              width={16}
              height={16}
            />
          </div>
          <span
            className="name-assistant one-line font-size-14"
            style={{ maxWidth: 300 }}
          >
            {assistantRole}
          </span>
        </div>
        <div className="wrapper-icon">
          <IconChevronDown size={14} />
        </div>
      </button>
      <ModalGlobal
        id="modal-select-assistant"
        open={showModalAssistant}
        showBtnClose={false}
        classNameChildren={`p-4 modal-children-select-assistant ${
          showModalAssistant
            ? "animate__animated animate__fadeInDown animate__faster"
            : "animate__animated animate__fadeOut"
        }`}
        onClick={handleClose}
      >
        <div
          className="d-flex flex-column gap-4 w-100 h-100"
          style={{ width: 700 }}
        >
          <div className="d-flex justify-content-between">
            <h3 className="m-0">{t("title")}</h3>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 30,
                height: 30,
                borderRadius: 6.4,
                background: "#F2F4F7",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <IconX stroke={2} size={18} />
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="gap-2 rounded-4 align-items-center h-100">
              <InstantSearch indexName="employees" searchClient={searchClient}>
                <SearchBox onChange={e => setSearchTerm(e.target.value)} />
                {searchTerm.length > 1 ? (
                  <Hits hitComponent={SearchedAssistant} />
                ) : (
                  <TabsModalAssistants
                    community={
                      <ListCommunityAssistantsModal
                        handleClose={handleClose}
                        activeAssistant={activeAssistant}
                        setActiveAssistant={setActiveAssistant}
                        state={state}
                      />
                    }
                    aizzy={
                      <ListAizzyAssistantsModal
                        handleClose={handleClose}
                        activeAssistant={activeAssistant}
                        setActiveAssistant={setActiveAssistant}
                      />
                    }
                    myAssistants={
                      <ListMyAssistantsModal
                        onClick={() => navigate("/assistants")}
                        handleClose={handleClose}
                        activeAssistant={activeAssistant}
                        setActiveAssistant={setActiveAssistant}
                      />
                    }
                  />
                )}
              </InstantSearch>
            </div>
          </div>
        </div>
      </ModalGlobal>
    </>
  );
};

export default ChangeAssistant;
