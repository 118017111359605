import { useState } from "react";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
} from "../../hooks/smallHooks";
import { showErrorNotification } from "../../services/notifications";
import { createDocumentEmbeddingUpload } from "../../api/embeddings";
import { queryClient } from "../../libs/react-query";

import { FileUploader } from "react-drag-drop-files";
import { toast } from "sonner";

const ComponentFileUploader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleCreateChannel } = useCreateNewChannel();
  const { chatData } = useGetConfigsMainChat() || {};

  const typesSupported = [
    "pdf",
    "docx",
    "pptx",
    "odt",
    "odp",
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "gif",
    "tif",
    "tiff",
    "tex",
    "txt",
    "rtf",
  ];

  const createChannelIfNecessary = async () => {
    if (!chatData) {
      return await handleCreateChannel();
    }
    return chatData;
  };

  async function handleSubmitFile(file) {
    if (isLoading) return;

    const currentChannel = await createChannelIfNecessary();

    try {
      setIsLoading(true);

      const toastId = toast.loading("Sending file...");

      await createDocumentEmbeddingUpload(currentChannel._id, file);

      await queryClient.invalidateQueries(["attachments", currentChannel._id]);

      toast.success("File sent successfully! Initializing processing...", {
        id: toastId,
      });
    } catch (error) {
      showErrorNotification(error.message || "Error when sending content");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="d-flex flex-column align-items-center gap-3 file-uploader-drop">
      <FileUploader
        classes="dropzone"
        maxSize={50}
        handleChange={file => handleSubmitFile(file)}
        types={typesSupported}
      />
    </div>
  );
};

export default ComponentFileUploader;
