export const ModalCreditsI18n = {
    "pt-BR": {
      label: "Créditos",
      method: {
        title: "Selecione o método de pagamento",
        card: "Cartão",
      },
      value: {
        title: "Valor dos créditos",
      },
      button: {
        pay: "Pagar",
        cancel: "Cancelar",
      },
      orderSummary: "Resumo do pedido",
      account : "Conta",
      chatbotCredits: "Créditos do chatbot",
    },
    "en-US": {
      label: "Credits",
      method: {
        title: "Select payment method",
        card: "Card",
      },
      value: {
        title: "Value credits",
      },
      button: {
        pay: "Pay",
        cancel: "Cancel",
      },
      orderSummary: "Order summary",
      account : "Account",
      chatbotCredits: "Chatbot credits",
    },
    "es-ES": {
      label: "Créditos",
      method: {
        title: "Seleccionar método de pago",
        card: "Tarjeta",
      },
      value: {
        title: "Valor de los créditos",
      },
      button: {
        pay: "Pagar",
        cancel: "Cancelar",
      },
      orderSummary: "Resumen del pedido",
      account : "Cuenta",
      chatbotCredits: "Créditos del chatbot",
    },
  };