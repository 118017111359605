import { useEffect, useState } from "react";
import {
  IconHeart,
  IconHeartFilled,
  IconLoader2,
  IconX,
} from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import COLORS from "../../utils/constants/colors";
import { QUERY_KEYS } from "../../libs/react-query";
import { useQuery } from "react-query";
import { getCommunityAssistantsByUserId } from "../../api/assistants";
import ButtonType1 from "../Buttons/ButtonType1";
import { useCreateNewChannel } from "../../hooks/smallHooks";
import { useNavigate } from "react-router-dom";
import { KEYS_MAIN_CHAT } from "../../services/chat/defaultConfigsMainChat";
import { setConfigsMainChat } from "../../redux/general/action";
import { useDispatch, useSelector } from "react-redux";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { ModalInfoAssistantsI18n } from "./ModalInfoAssistants.i18n";
import useTranslation from "../../hooks/useTranslation";
import { showRealoadNotification } from "../../services/notifications";
import { LikeAssistants } from "../Add-ons/LikeAssistants";
import { useUser } from "@supabase/auth-helpers-react";

const ModalAssistantInfo = ({ isOpen, toggleModal, assistant }) => {
  let showError = false;
  const { handleCreateChannel } = useCreateNewChannel();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { t } = useTranslation(ModalInfoAssistantsI18n);
  const user = useUser();
  const [selectedAssistant, setSelectedAssistant] = useState(assistant);

  const { data, isLoading, error } = useQuery(
    [QUERY_KEYS.MY_ASSISTANTS, { userId: selectedAssistant?.userId }],
    () =>
      getCommunityAssistantsByUserId({
        userId: assistant?.userId,
      }),
    {
      enabled: !!selectedAssistant?.userId,
    }
  );

  const userAssistants =
    (data &&
      data?.assistants?.filter(
        userAssistant => userAssistant._id !== selectedAssistant._id
      )) ||
    [];

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    setSelectedAssistant(assistant);
  }, [assistant]);

  const handleSelectAssistant = assistant => {
    setSelectedAssistant(assistant);
  };

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.SM;

  useEffect(() => {
    showRealoadNotification(error ? error.message : null, showError);
    showError = true;
  }, [error]);

  const getLikeCount = () => {
    const existingLikes = JSON.parse(localStorage.getItem("likesArray")) || [];
    const localCount = existingLikes.reduce((count, like) => {
      return like.id_assistant === (assistant?._id || assistant?.objectID)
        ? like.cont_likes
        : count;
    }, 0);

    return localCount || assistant?.likes || 0;
  };

  const isLikedInLocalStorage = JSON.parse(
    localStorage.getItem("likesArray")
  )?.some(
    like =>
      like.id_assistant === (assistant?._id || assistant?.objectID) &&
      like.flag_like === true
  );

  const isUserLiked =
    isLikedInLocalStorage ||
    selectedAssistant?.likedby?.some(like => like === user?.id);

  return (
    <ModalGlobal
      open={isOpen}
      classNameChildren={`p-sm-4 p-3 modal-children-info-assistants justify-content-start ${
        isOpen
          ? "animate__animated animate__fadeInDown animate__faster"
          : "animate__animated animate__fadeOut"
      }`}
      showBtnClose={false}
      onClick={toggleModal}
    >
      <div
        className="d-flex flex-column w-100 h-100 justify-content-between"
        style={{ gap: isMobile ? 24 : 32 }}
      >
        <header className="d-flex justify-content-end">
          <button
            className="d-flex align-items-center"
            style={{
              padding: "8px 8px",
              borderRadius: 6,
              background: COLORS.gray100,
            }}
            type="button"
            onClick={toggleModal}
          >
            <IconX stroke={1.5} style={{ width: 16, height: 16 }} />
          </button>
        </header>
        <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
          <div>
            <img src={selectedAssistant?.profileImage} alt="" />
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ gap: 8 }}
          >
            <div className="d-flex justify-content-center">
              <h3 className="two-line text-center fw-semibold">
                {selectedAssistant?.role}
              </h3>
            </div>
            <div className="d-flex justify-content-center gap-1">
              <span>{t("create")}</span>
              <div className="d-flex gap-2 align-items-center small">
                {/* <img
                  src={selectedAssistant?.profileImage}
                  alt=""
                  style={{ width: 20, height: 20, borderRadius: "50%" }}
                /> */}
                {selectedAssistant?.userName}
              </div>
            </div>
            <div className="px-4">
              <p className="text-center m-0 three-line">
                {selectedAssistant?.about || t("elevate")}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3">
          <div
            className="d-flex flex-column gap-2 p-3"
            style={{
              width: "100%",
              background: COLORS.gray100,
              borderRadius: 16,
            }}
          >
            <h3 className={`${isMobile ? "font-size-16" : "font-size-20"}`}>
              # {userAssistants.length}
            </h3>
            <span>{t("productivity")}</span>
          </div>
          <div
            className="d-flex flex-column gap-2 p-3"
            style={{
              width: "100%",
              background: COLORS.gray100,
              borderRadius: 16,
            }}
          >
            <div className="d-flex gap-1 align-items-center">
              {isUserLiked ? (
                <IconHeartFilled
                  stroke={1.5}
                  color="red"
                  className="sizeIconLike"
                />
              ) : (
                <IconHeart stroke={1.5} className="sizeIconLike" />
              )}
              <h3>{getLikeCount()}</h3>
            </div>

            <span>{t("likes")}</span>
          </div>
        </div>
        <div
          className="gap-3 d-flex flex-column"
          style={{ flexGrow: 1, overflowY: "auto" }}
        >
          <strong className="m-0">{t("more")}:</strong>
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center mt-3">
              <IconLoader2 size={30} className="spin" />
            </div>
          ) : (
            <div
              className="d-flex flex-column gap-3"
              style={{ flexGrow: 1, overflowY: "auto" }}
            >
              {userAssistants && userAssistants?.length > 0 ? (
                userAssistants.map(assistant => (
                  <div
                    key={assistant._id}
                    className="d-flex align-items-center justify-content-start gap-3 px-3"
                    style={{
                      background: COLORS.gray100,
                      borderRadius: 16,
                      minHeight: 100,
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelectAssistant(assistant)}
                  >
                    <img
                      src={assistant.profileImage}
                      alt=""
                      style={{ width: 50, height: 50, borderRadius: "50%" }}
                    />
                    <div>
                      <h4 className="m-0">{assistant.name}</h4>
                      <span className="two-line">
                        {assistant.about ||
                          "Enhance your writing skills with AI guidance for clear, impactful, and persuasive copy"}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="m-0">
                  {t("onlyCreated")} {selectedAssistant?.userName}
                </p>
              )}
            </div>
          )}
          <div>
            <ButtonType1
              text={t("buttonChat")}
              variation="primary"
              props={{
                className: "btn-socials",
                style: { width: "100%", height: 40, borderRadius: 10 },
                onClick: () => {
                  toggleModal && handleCreateChannel() && handleClick();
                  dispatch(
                    setConfigsMainChat(
                      KEYS_MAIN_CHAT.assistant,
                      selectedAssistant
                    )
                  );
                },
              }}
            />
          </div>
        </div>
      </div>
    </ModalGlobal>
  );
};

export default ModalAssistantInfo;
