export const GroupLoginI18n = {
  "pt-BR": {
    signIn: "Entrar",
    signUp: "Inscrever-se",
    recoverPassword: "Recuperar senha",
    backToLogin: "Voltar para o login",
    getStarted: "Começar",
    createAccount: "Criar conta",
    forgotPassword: "Esqueceu a senha?",
    sendEmail: "Enviar e-mail",
    sendEmailSuccessNotification: "E-mail enviado com sucesso!",
    email: "E-mail",
    password: "Senha",
    login: "Login",
    newAizzy: "Novo no Aizzy?",
    okAccount: "Já tem uma conta?",
    google: "Entrar com Google",
    metaMask: "Entrar com MetaMask",
    placeholderEmail: "Seu endereço de e-mail",
    placeholderPassword: "Sua senha",
    wallet: "Conectar carteira",
  },
  "es-ES": {
    signIn: "Iniciar sesión",
    signUp: "Registrarse",
    recoverPassword: "Recuperar contraseña",
    backToLogin: "Volver al inicio de sesión",
    getStarted: "Empezar",
    createAccount: "Crear cuenta",
    forgotPassword: "¿Olvidaste la contraseña?",
    sendEmail: "Enviar correo electrónico",
    sendEmailSuccessNotification: "Correo enviado con éxito!",
    email: "Correo electrónico",
    password: "Contraseña",
    login: "Iniciar sesión",
    newAizzy: "¿Nuevo en Aizzy?",
    okAccount: "¿Ya tienes una cuenta?",
    google: "Iniciar sesión con Google",
    metaMask: "Iniciar sesión con MetaMask",
    placeholderEmail: "Tu correo electrónico",
    placeholderPassword: "Tu contraseña",
    wallet: "Conectar billetera",
  },
  "en-US": {
    signIn: "Sign In",
    signUp: "Sign Up",
    recoverPassword: "Recover Password",
    backToLogin: "Back to Login",
    getStarted: "Get Started",
    createAccount: "Create Account",
    forgotPassword: "Forgot your password?",
    sendEmail: "Send Email",
    sendEmailSuccessNotification: "Email sent successfully!",
    email: "Email",
    password: "Password",
    login: "Login",
    newAizzy: "New to Aizzy?",
    okAccount: "Already have an account?",
    google: "Sign in with Google",
    metaMask: "Sign in with MetaMask",
    placeholderEmail: "Your email address",
    placeholderPassword: "Your password",
    wallet: "Connect wallet",
  },
};
