const UserSubscriptionI18n = {
  pt: {
    subscription: {
      subscriber: "Assinante",
      annual: "Anual",
      monthly: "Mensal",
      validUntil: "Assinatura válida até",
      explanation:
        "Sua assinatura atual permanecerá ativa até a data de expiração. Após essa data, suas interações passarão a ser cobradas em créditos. Antes, o Aizzy funcionava por assinatura; agora, a cobrança é feita por créditos.",
      creditExplanationTitle: "Exemplo de uso e débito de créditos:",
      creditExample1:
        '1 - Usuário envia a mensagem: "Olá, qual é a previsão do tempo para amanhã?"',
      creditExample1Details:
        'Tamanho da solicitação: pequeno.\nModelo usado: básico.\nCusto: $0,01 (em créditos).\nResposta: "A previsão do tempo para amanhã é de sol com algumas nuvens, temperatura entre 25°C e 30°C."',
      creditExample2: '2 - Usuário faz a pergunta: "O que é o ciclo da água?"',
      creditExample2Details:
        'Tamanho da solicitação: médio.\nModelo usado: intermediário.\nCusto: $0,05 (em créditos).\nResposta: "O ciclo da água é o processo contínuo de movimentação da água na Terra, incluindo evaporação, condensação, precipitação e infiltração."',
      creditDeducted: "Crédito total descontado da conta: $0,06.",
      totalDebit:
        "Neste exemplo, o usuário foi debitado $0,06 em créditos por duas interações.",
    },
  },
  en: {
    subscription: {
      subscriber: "Subscriber",
      annual: "Annual",
      monthly: "Monthly",
      validUntil: "Valid until",
      explanation:
        "Your current subscription will remain active until the expiration date. After that, interactions will be charged using credits. Previously, Aizzy was subscription-based; now it's credit-based.",
      creditExplanationTitle: "Example of credit usage and deduction:",
      creditExample1:
        '1 - User sends the message: "Hello, what\'s the weather forecast for tomorrow?"',
      creditExample1Details:
        'Request size: small.\nModel used: basic.\nCost: $0.01 (in credits).\nResponse: "The weather forecast for tomorrow is sunny with some clouds, temperatures between 25°C and 30°C."',
      creditExample2: '2 - User asks: "What is the water cycle?"',
      creditExample2Details:
        'Request size: medium.\nModel used: intermediate.\nCost: $0.05 (in credits).\nResponse: "The water cycle is the continuous movement of water on Earth, including evaporation, condensation, precipitation, and infiltration."',
      creditDeducted: "Total credits deducted: $0,06.",
      totalDebit:
        "In this example, the user was debited $0.06 in total for two interactions.",
    },
  },
  es: {
    subscription: {
      subscriber: "Suscriptor",
      annual: "Anual",
      monthly: "Mensual",
      validUntil: "Suscripción válida hasta",
      explanation:
        "Tu suscripción actual permanecerá activa hasta la fecha de expiración. Después de esa fecha, tus interacciones se cobrarán con créditos. Anteriormente, Aizzy era por suscripción, ahora es por créditos.",
      creditExplanationTitle: "Ejemplo de uso y deducción de créditos:",
      creditExample1:
        '1 - Usuario envía el mensaje: "Hola, ¿cuál es el pronóstico del tiempo para mañana?"',
      creditExample1Details:
        'Tamaño de la solicitud: pequeño.\nModelo utilizado: básico.\nCosto: $0,01 (en créditos).\nRespuesta: "El pronóstico del tiempo para mañana es soleado con algunas nubes, temperaturas entre 25°C y 30°C."',
      creditExample2: '2 - Usuario pregunta: "¿Qué es el ciclo del agua?"',
      creditExample2Details:
        'Tamaño de la solicitud: mediano.\nModelo utilizado: intermedio.\nCosto: $0,05 (en créditos).\nRespuesta: "El ciclo del agua es el proceso continuo de movimiento del agua en la Tierra, que incluye evaporación, condensación, precipitación e infiltración."',
      creditDeducted: "Total de créditos descontados: $0,06.",
      totalDebit:
        "En este ejemplo, se descontaron $0,06 en créditos por dos interacciones.",
    },
  },
};

export default UserSubscriptionI18n;
