//redux
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { AppDispatch, RootState } from "../redux/store";

const useRedux = () => {
  const dispatch: AppDispatch = useDispatch();
  const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  return { dispatch, useAppSelector };
};

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { useRedux, useAppSelector };
