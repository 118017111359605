import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../libs/react-query";
import { getCompanyAssistants } from "../../../api/assistants";
import Skeleton from "react-loading-skeleton";
import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";
import { useEffect, useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { PageAssistantsI18n } from "./HeaderPageAssistants/HeaderPageAssistants.i18n";
import { showRealoadNotification } from "../../../services/notifications";

const AssistantsAizzyApp = ({ renderAizzyTabProp }) => {
  let showError = false;
  const dispatch = useDispatch();
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  const { t } = useTranslation(PageAssistantsI18n);

  const { data, isLoading, error } = useQuery(
    QUERY_KEYS.AIZZY_ASSISTANTS,
    getCompanyAssistants,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const assistants = data?.assistants;

  const renderSkeletons = [...Array(6)].map((_, index) => (
    <Skeleton
      key={`skeleton-${index}`}
      baseColor={"#e4e4e7"}
      highlightColor={"#F1F1F1"}
      duration={1}
      height={132}
      borderRadius={16}
    />
  ));

  const handleCardClick = (assistant) => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
 }, [error]);

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-column gap-2">
        <h3>{t("subTitles.byAizzyApp")}</h3>
        <span>{t("subTitles.paragraphAizzyApp")}</span>
      </div>
      <div className="grid-vertical-assistants">
        {isLoading
          ? renderSkeletons
          : assistants && assistants
            ?.slice(0, 6)
            .map((assistant, index) => (
              <CardAssistantsNewLayout
                setOpenModalInfo={() => handleCardClick(assistant)}
                key={index}
                direction="vertical"
                image={assistant.profileImage}
                title={assistant.name}
                description={assistant.role}
                icon={assistant.profileImage}
                name={assistant.role}
                assistant={assistant}
                onSelect={() =>
                  dispatch(
                    setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant)
                  )
                }
              />
            ))}
      </div>
      <button
        onClick={renderAizzyTabProp}
        className="w-100 d-flex align-items-center justify-content-center button-footer-grid-asssistants"
      >
        {t("button")}
      </button>
      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => { setOpenModalInfo(false) }}
        assistant={selectedAssistant}
      />
    </div>
  );
};

export default AssistantsAizzyApp;
