import { IconCheck } from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import useTranslation from "../../../hooks/useTranslation";
import { ModalSelectModelI18n } from "../ModalSelectModel.i18n";
import { useState } from "react";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import ButtonType1 from "../../Buttons/ButtonType1";

export const ListModelFlat = ({
  machines,
  activeModel,
  setActiveModel,
  searchModel,
}) => {
  const [showButton, setShowButton] = useState(null);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const state = useSelector(state => state);
  const { t } = useTranslation(ModalSelectModelI18n);
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.MD;

  const filteredMachines = searchModel
    ? machines?.filter(
        machine =>
          machine.name?.toLowerCase().includes(searchModel.toLowerCase()) ||
          machine.id.toLowerCase().includes(searchModel.toLowerCase()) ||
          machine.description.toLowerCase().includes(searchModel.toLowerCase())
      )
    : machines;

  return (
    <>
      {!openModalDetails ? (
        <ul className="animate__animated animate__fadeIn animate__faster">
          {filteredMachines && filteredMachines.map((machine, idx) => (
            <li
              className={`${
                activeModel === machine.id ? "active" : ""
              } d-flex align-items-center justify-content-between`}
              key={machine.id}
              onClick={() => {
                setActiveModel(machine.id);
              }}
              onMouseEnter={() => setShowButton(idx)}
              onMouseLeave={() => setShowButton(null)}
            >
              <div className="d-flex gap-4 align-items-center">
                <p
                  className="m-0 font-size-12 one-line"
                  style={{ padding: "3px 0" }}
                >
                  {machine.name}
                </p>
                {showButton === idx && (
                  <button
                    style={{
                      background: COLORS.white,
                      padding: "3px 10px",
                      borderRadius: 6,
                    }}
                    type="button"
                    className="d-flex align-items-center font-size-11"
                    onClick={e => {
                      e.stopPropagation();
                      setOpenModalDetails(true);
                      setSelectedMachine(machine);
                    }}
                  >
                    {t("details.buttonDetails")}
                  </button>
                )}
              </div>
              <div className="d-flex gap-3 align-items-center">
                {!isMobile && (
                  <p
                    style={{
                      background: COLORS.white,
                      padding: "3px 10px",
                      borderRadius: 6,
                    }}
                    type="button"
                    className="m-0 d-flex align-items-center font-size-11"
                  >
                    $
                    {(machine.pricing.completion * 1000000)
                      .toString()
                      .slice(0, 5)}{" "}
                    {t("list.tokeWeb")}
                  </p>
                )}
                {activeModel === machine.id && (
                  <IconCheck stroke={1.5} width={16} height={16} />
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="animate__animated animate__fadeIn details-model d-flex flex-column gap-3">
          <h4 className="m-0">{t("details.details")}:</h4>
          <ul className="d-flex flex-column gap-2">
            <li className="d-flex flex-column justify-content-center align-items-start">
              <span>{t("details.name")}:</span>
              <p>{selectedMachine.name}</p>
            </li>
            <li className="d-flex flex-column justify-content-center align-items-start">
              <span>
                {t("details.description", {
                  text: selectedMachine.description,
                })}
                :
              </span>
              <p>{selectedMachine.description}</p>
            </li>
            <li className="d-flex flex-column justify-content-center align-items-start">
              <span>{t("details.modality")}:</span>
              <p>{selectedMachine.architecture.modality.split("-")[0]}</p>
            </li>
            {isMobile && (
              <li className="d-flex flex-column justify-content-center align-items-start">
                <span>{t("list.token")}:</span>
                <p>
                  $
                  {(selectedMachine.pricing.completion * 1000000)
                    .toString()
                    .slice(0, 5)}
                </p>
              </li>
            )}
          </ul>
          <div className="d-flex justify-content-center gap-3 mt-3">
            <ButtonType1
              text={t("details.back")}
              props={{
                style: { padding: "10px 20px", borderRadius: 8 },
                type: "button",
                onClick: () => {
                  setOpenModalDetails(false);
                },
              }}
            />
            <ButtonType1
              text={t("details.selectModel")}
              props={{
                style: { padding: "10px 20px", borderRadius: 8 },
                type: "button",
                onClick: () => {
                  setOpenModalDetails(false);
                  setActiveModel(selectedMachine.id);
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
