import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(config => {
  try {
    const token = JSON.parse(
      localStorage.getItem("sb.token") || "{}"
    )?.access_token;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  } catch (error) {
    console.error("Erro ao parsear o token:", error);
  }
  return config;
});

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error?.response?.status) {
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = "Sorry! try again in a few seconds";
        break;
    }

    return Promise.reject({
      message:
        message ||
        error?.response?.data?.message ||
        (typeof error?.response?.data === "object"
          ? JSON.stringify(error.response.data)
          : error?.response?.data) ||
        error?.message,
      success: error?.response?.data?.success,
      code: error?.response?.data?.code,
    });
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: { headers?: any }): any => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}, config?: { headers?: any }): any => {
    return axios.post(url, data, config); // Agora aceitando config como terceiro argumento
  };

  /**
   * post given data to url
   */
  stream = (url: string, data?: {}): any => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}): any => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}): any => {
    return axios.delete(url, { ...config });
  };

  /*
   file upload update method
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, formData, config);
  };

  /*
   file upload post method
   */
  createWithFile = (
    url: string,
    data: any,
    setUploadProgress?: (progress: number) => void
  ) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
      // onUploadProgress: (progressEvent: ProgressEvent) => {},
    };
    return axios.post(url, formData, config);
  };
}

export { APIClient, setAuthorization };
