import { IconLoader2 } from "@tabler/icons-react";

const ButtonType1 = ({
  text,
  props,
  variation = "primary",
  isLoading,
  icon,
  onClick,
}) => {
  const typesButton = [
    {
      variation: "primary",
      className: "button-primary",
    },
    {
      variation: "secondary",
      className: "button-secondary",
    },
    {
      variation: "thirty",
      className: "button-thirty",
    },
  ];

  return (
    <button
      {...props}
      disabled={props.disabled || isLoading}
      className={`${
        typesButton.find(item => item.variation === variation).className
      } ${props?.className}`}
    >
      {isLoading ? (
        <IconLoader2 className="spin" size={16} />
      ) : (
        <>
          {icon && (
            <img
              src={icon}
              alt={text}
              style={{ marginRight: 8 }}
              width={props?.width || 24}
              height={props?.height || 24}
            />
          )}
          {text}
        </>
      )}
    </button>
  );
};

export default ButtonType1;
