import { cryptoService } from "../crypto";
import { MessageData } from "../../@DTO/chat";
import { BaseService } from "./base";

export const storeNames = {
  users: "users",
  assistants: "assistants",
  chats: "chats",
  documents: "documents",
};

export class ChatRepository extends BaseService {
  constructor() {
    super(storeNames.chats);
  }

  async updateChatWithMessage(id: string, newMessage: MessageData) {
    try {
      const chat = await this.db.table(this.storeName).get(id);

      if (!chat) {
        console.warn(`Chat with id ${id} not found in ${this.storeName}`);
        return null;
      }

      if (!chat.messages || !Array.isArray(chat.messages)) {
        chat.messages = [];
      }

      const decryptedMessages = chat.messages.map(
        (encryptedMessage: string) => {
          const decrypted = cryptoService.decrypt(encryptedMessage);
          return JSON.parse(decrypted);
        }
      );

      const existingMessageIndex = decryptedMessages.findIndex(
        (message: MessageData) => message._id === newMessage._id
      );

      if (existingMessageIndex !== -1) {
        const existingMessage = decryptedMessages[existingMessageIndex];

        if (
          existingMessage.chat_response &&
          existingMessage.chat_response.content === ""
        ) {
          if (
            newMessage.chat_response &&
            newMessage.chat_response.content !== ""
          ) {
            decryptedMessages[existingMessageIndex] = newMessage;
          }
        }
      } else {
        decryptedMessages.push(newMessage);
      }

      const encryptedMessages = decryptedMessages.map((message: MessageData) =>
        cryptoService.encrypt(JSON.stringify(message))
      );

      await this.db
        .table(this.storeName)
        .update(id, { messages: encryptedMessages });
    } catch (error) {
      console.error(
        `Error updating chat with message in ${this.storeName} for id ${id}:`,
        error
      );
      throw error;
    }
  }

  async getMessagesByChatId(id: string) {
    try {
      const chat = await this.db.table(this.storeName).get(id);

      if (!chat) return null;

      const decryptedMessages: MessageData[] = chat?.messages?.map(message => {
        return JSON.parse(cryptoService.decrypt(message));
      });

      return decryptedMessages;
    } catch (error) {
      console.error(
        `Error fetching messages for chat id ${id} from ${this.storeName}:`,
        error
      );
      throw error;
    }
  }

  async clearChatById(id: string) {
    try {
      await this.db.table(this.storeName).update(id, { messages: [] });
    } catch (error) {
      console.error(
        `Error clearing messages for chat id ${id} in ${this.storeName}:`,
        error
      );
      throw error;
    }
  }

  async deleteMessageById(chatId: string, messageId: string) {
    try {
      const chat = await this.db.table(this.storeName).get(chatId);

      if (!chat) {
        console.warn(`Chat with id ${chatId} not found in ${this.storeName}`);
        return null;
      }

      const decryptedMessages: MessageData[] = chat.messages.map(message => {
        return JSON.parse(cryptoService.decrypt(message));
      });

      const updatedMessages = decryptedMessages.filter(
        message => message._id !== messageId
      );

      const encryptedMessages = updatedMessages.map(message => {
        return cryptoService.encrypt(JSON.stringify(message));
      });

      await this.db
        .table(this.storeName)
        .update(chatId, { messages: encryptedMessages });
    } catch (error) {
      console.error(
        `Error deleting message ${messageId} from chat ${chatId} in ${this.storeName}:`,
        error
      );
      throw error;
    }
  }

  async deleteChatsByAssistantId(assistantId: string, generalChatId: string) {
    try {
      const chats = (await this.db.table(this.storeName).toArray()) as {
        id: string;
        messages: string[];
      }[];

      const chatsToDelete = chats
        .filter(
          chat =>
            chat.id.split("_")[1] === assistantId &&
            chat.id.split("_")[0] !== generalChatId
        )
        .map(chat => chat.id);

      await this.db.table(this.storeName).bulkDelete(chatsToDelete);
    } catch (error) {
      console.error(
        `Error deleting chats for assistant ${assistantId} in ${this.storeName}:`,
        error
      );
      throw error;
    }
  }

  async deleteAllChats() {
    try {
      await this.db.table(this.storeName).clear();
    } catch (error) {
      console.error(`Error deleting all chats in ${this.storeName}:`, error);
      throw error;
    }
  }
}

export function makeChatId(channelId: string, userId: string) {
  return `${channelId}_${userId}`;
}
