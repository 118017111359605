import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState } from "react";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../services/notifications";

import ButtonType1 from "../../Buttons/ButtonType1";

export function RecoverPassword({ t, recoverEmail }) {
  const [email, setEmail] = useState(recoverEmail);
  const [isLoading, setIsLoading] = useState(false);

  const supabaseClient = useSupabaseClient();

  async function handleRecoverPassword() {
    if (!email) {
      return;
    }

    try {
      setIsLoading(true);

      const { error } = await supabaseClient.auth.resetPasswordForEmail(
        email.trim(),
        {
          redirectTo: encodeURI(
            `${process.env.REACT_APP_PUBLIC_SITE_URL}/settings?tab=security&recoverPassword=true`
          ),
        }
      );

      if (error) {
        throw new Error(error.message);
      }

      showSuccessNotification(t("sendEmailSuccessNotification"));
    } catch (error) {
      showErrorNotification(
        error?.message ||
          "Failed to reset password, please try again or contact support"
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form
      action=""
      onSubmit={e => {
        e.preventDefault();
      }}
      className="form-login-web2"
    >
      <div className="group">
        <label htmlFor="email">{t("email")}</label>
        <input
          type="text"
          name="email"
          id="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder={t("placeholderEmail")}
        />
      </div>

      <ButtonType1
        text={t("sendEmail")}
        isLoading={isLoading}
        variation="primary"
        props={{
          onClick: handleRecoverPassword,
          className: "btn-main-form",
          type: "button",
        }}
      />
    </form>
  );
}
