import { IconCheck, IconLoader2 } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { QUERY_KEYS } from "../../../libs/react-query";
import { getMyAssistants } from "../../../api/assistants";
import { setConfigsMainChat } from "../../../redux/general/action";
import { showRealoadNotification } from "../../../services/notifications";
import CardCreateAssistant from "../../Cards/CardCreateAssistant";

const ListMyAssistantsModal = ({
  activeAssistant,
  setActiveAssistant,
  state,
  handleClose,
  onClick
}) => {
  let showError = false;
  const dispatch = useDispatch();
  const [setAssistant] = useState(
    state?.configsGeneralReducer?.configsMainChat?.[KEYS_MAIN_CHAT.assistant]
      ?.name
  );

  const { data, isLoading, error } = useQuery(
    QUERY_KEYS.MY_ASSISTANTS,
    getMyAssistants,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  const assistants = data?.assistants;

  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
  }, [error]);

  return (
    <div className="d-flex flex-column gap-3">
      <ul
        style={{
          gap: 16,
          display: "flex",
          flexDirection: "column",
          marginBottom: 16,
        }}
      >
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <IconLoader2 className="spin" size={24} />
          </div>
        ) : (
          <>
            {assistants && assistants?.map(assistant => (
              <li
                style={{
                  minHeight: 44,
                  padding: "0 10px",
                  cursor: "pointer",
                  borderRadius: 8,
                }}
                key={assistant.name}
                className={`d-flex px= gap-3 align-items-center list-assistants-modal ${activeAssistant?.name === assistant?.name ? "active" : ""
                  }`}
                onClick={() => {
                  handleClose();
                  dispatch(
                    setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant)
                  );
                  setAssistant(assistant.name);
                  setActiveAssistant(assistant);
                }}
              >
                <img
                  src={assistant.profileImage}
                  alt={assistant.name}
                  loading="lazy"
                  width={24}
                  height={24}
                  style={{ borderRadius: "50%" }}
                />
                <span>{assistant.name}</span>
                {activeAssistant?.name === assistant?.name && (
                  <IconCheck stroke={1.5} size={14} className="active-icon" />
                )}
              </li>
            ))}
          </>
        )}
      </ul>
      {assistants?.length < 1 && (
        <CardCreateAssistant onClick={onClick}/>
      )}
    </div>
  );
};

export default ListMyAssistantsModal;
