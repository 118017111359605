import { APIClient } from "./apiCore";

const api = new APIClient();
const paymentApi = process.env.REACT_APP_CREDIT_API_URL;

// Interfaces de retorno de dados

export interface PaymentUserResponse {
  id: string;
  user_id: string;
  amount: number;
  method: PaymentMethod; // "card" | "pix" | "token"
  transaction_id: string;
  status: PaymentStatus; // "pending" | "completed" | etc.
  payment_url: string; // URL for payment completion if applicable
  error: string; // Error message if any
  created_at: string; // ISO 8601 date-time string
  updated_at: string; // ISO 8601 date-time string
}

export interface CreditsResponse {
  id: string;
  amount: number;
  user_id: string;
  created_at: string; // ISO 8601 date-time string
  updated_at: string; // ISO 8601 date-time string
}

export interface PaymentResponse {
  id: string;
  amount: number;
  status: PaymentStatus; // ex: "pending", "completed"
  payment_url: string; // URL for payment completion
  pix?: {
    owner: string; // Owner of the PIX account
    personalid: string; // CNPJ or CPF
    keypix: string; // PIX key
    qrcode: string; // Base64 QR code
    txinfoemvqrcps: string; // Transaction information for copy-paste
  };
}

export type PaymentMethod = "card" | "pix" | "token";

export type PaymentStatus =
  | "pending"
  | "completed"
  | "failed"
  | "canceled"
  | "in_process";

interface PaymentRequest {
  amount: number;
  method: "card" | "pix" | "token";
  transaction_id?: string;
}

const creditApiKey = process.env.REACT_APP_CREDIT_API_KEY;

const getHeaders = () => ({
  "x-api-key": creditApiKey,
});

export async function getUserCredits(): Promise<CreditsResponse> {
  return api.get(`${paymentApi}/credit/user`, {
    headers: getHeaders(),
  });
}

export async function getUserSubscription(): Promise<{
  id: string;
  user_id: string;
  status: "active" | "inactive";
  current_period_end: string;
  current_period_start: string;
}> {
  return api.get(`${paymentApi}/subscription/user`, {
    headers: getHeaders(),
  });
}

// GET /payment/user
export async function getUserPaymentHistory(): Promise<PaymentUserResponse[]> {
  return api.get(`${paymentApi}/payment/user`, {
    headers: getHeaders(),
  });
}

// GET /payment/{id}
export async function getUserPaymentById(id: string): Promise<PaymentResponse> {
  return api.get(`${paymentApi}/payment/${id}`, {
    headers: getHeaders(),
  });
}

// POST /payment
export async function postPayment(
  data: PaymentRequest
): Promise<PaymentResponse> {
  return api.create(`${paymentApi}/payment`, data, {
    headers: getHeaders(),
  });
}
