/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { getBearerToken } from "../services/localStorageHelpers";
import { useLocation } from "react-router-dom";

export const AuthProtected = ({ children }) => {
  const token = getBearerToken();
  //const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {/*
    const isRecoverPasswordRoute =
      location.pathname === "/settings" &&
      new URLSearchParams(location.search).get("recoverPassword") === "true";
*/
    // if (!token && !isRecoverPasswordRoute) {
    //   navigate("/");
    // }
  }, [token, location]);

  return children;
};
