import { useState } from "react";
import IconArrowDown from "../../../../assets/images/icons-new-layout/arrow-down.svg";
import { useGetIsLogged } from "../../../../hooks/smallHooks";
import { setOpenLogin } from "../../../../redux/general/action";
import ModalSelectModel from "../../../Modals/ModalSelectModel";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import { useChatStore } from "../../../../state/chat";
import COLORS from "../../../../utils/constants/colors";
import { useDispatch } from "react-redux";

const ChangeMachine = ({ state, background }) => {
  const [openModal, setOpenModal] = useState(false);
  const storedConfigs = JSON.parse(localStorage.getItem("configsMainChat"));
  const selectedMachine =
    storedConfigs?.machine?.name || storedConfigs?.machine;
  const isLogged = useGetIsLogged();

  const dispatch = useDispatch();

  const [searchModel, setSearchModel] = useState("");

  const { isReceivingMessage } = useChatStore();

  const groupAndSortMachines = machines => {
    const groupedMachines = machines.reduce((acc, machine) => {
      const owner = machine.architecture?.tokenizer || "Default";

      if (owner.toLowerCase() === "router") return acc;

      if (!acc[owner]) {
        acc[owner] = [];
      }
      acc[owner].push(machine);
      return acc;
    }, {});

    const sortedGroups = Object.keys(groupedMachines)
      .sort()
      .map(owner => ({
        owner,
        machines: groupedMachines[owner].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      }));

    return sortedGroups;
  };

  const allModels = state?.configsGeneralReducer?.listMachines?.filter(
    machine =>
      machine &&
      !machine.name.toLowerCase().includes(":free") &&
      !machine.id.toLowerCase().includes(":free")
  );

  const allModelsImage = allModels?.filter(
    machine => machine.architecture.modality === "text+image->text"
  );

  const allModelsCode = allModels?.filter(
    machine =>
      machine.name.toLowerCase().includes("code") ||
      machine.id.toLowerCase().includes("code") ||
      machine.description.toLowerCase().includes("code")
  );

  const allModelsChats = allModels?.filter(
    machine =>
      machine.architecture.modality !== "text+image->text" &&
      machine.architecture.modality === "text->text"
  );

  const machinesToDisplay = groupAndSortMachines(
    allModels?.filter(
      machine =>
        (machine.name.toLowerCase().includes(searchModel.toLowerCase()) ||
          machine.id.toLowerCase().includes(searchModel.toLowerCase()) ||
          machine.description
            .toLowerCase()
            .includes(searchModel.toLowerCase())) &&
        machine.architecture.modality !== "text+image->text" &&
        machine.architecture.modality === "text->text"
    ) || []
  );

  const machinesToDisplayCode = groupAndSortMachines(
    allModelsCode?.filter(
      machine =>
        machine.name.toLowerCase().includes(searchModel.toLowerCase()) ||
        machine.id.toLowerCase().includes(searchModel.toLowerCase()) ||
        machine.description.toLowerCase().includes(searchModel.toLowerCase())
    ) || []
  );

  const sortedCheaperModels = allModels
    ?.slice()
    .sort((a, b) => {
      return (
        parseFloat(a.pricing?.completion || 0) -
        parseFloat(b.pricing?.completion || 0)
      );
    })
    .filter(model => parseFloat(model.pricing?.completion || 0) > 0);

  const machinesToDisplayCheaper =
    sortedCheaperModels?.filter(
      machine =>
        machine.name.toLowerCase().includes(searchModel.toLowerCase()) ||
        machine.id.toLowerCase().includes(searchModel.toLowerCase()) ||
        machine.description.toLowerCase().includes(searchModel.toLowerCase())
    ) || [];

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.SM;

  return (
    <>
      <button
        style={{
          border: "none",
          borderRadius: isMobile ? 6 : 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          background: background,
          padding: "0 12px",
          gap: 12,
          height: 40,
        }}
        onClick={() => {
          if (isLogged) {
            setOpenModal(true);
          } else {
            dispatch(setOpenLogin(true));
          }
        }}
        type="button"
        disabled={isReceivingMessage}
      >
        <span
          className="one-line text-capitalize font-size-14"
          style={{ color: COLORS.gray700 }}
        >
          {selectedMachine}
        </span>
        <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
      </button>
      <ModalSelectModel
        openModal={openModal}
        setOpenModal={setOpenModal}
        searchModel={searchModel}
        setSearchModel={setSearchModel}
        machinesToDisplay={machinesToDisplay}
        machinesToDisplayCode={machinesToDisplayCode}
        machinesToDisplayCheaper={machinesToDisplayCheaper}
        allModels={allModels}
        allModelsChats={allModelsChats}
        allModelsImage={allModelsImage}
        allModelsCode={allModelsCode}
      />
    </>
  );
};

export default ChangeMachine;
