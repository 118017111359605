import { useDispatch, useSelector } from "react-redux";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { createChannel } from "../api";
import {
  KEYS_MAIN_CHAT,
  LIST_TYPE_CHAT,
} from "../services/chat/defaultConfigsMainChat";
import { setConfigsMainChat, setIsLogged } from "../redux/general/action";
import {
  ChatRepository,
  makeChatId,
} from "../services/indexedDB/chat-repository";
import { showErrorNotification } from "../services/notifications";
import Cookies from "js-cookie";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useState } from "react";

const useGetConfigsMainChat = () => {
  const state = useSelector(state => state);
  return state?.configsGeneralReducer?.configsMainChat;
};

const useGetIsLogged = () => {
  const state = useSelector(state => state);
  return state?.configsGeneralReducer?.isLogged;
};

const useLogout = () => {
  const supabaseClient = useSupabaseClient();
  const dispatch = useDispatch();
  return async () => {
    const cookies = Object.keys(Cookies.get());
    cookies.forEach(cookie => {
      Cookies.remove(cookie);
    });
    localStorage.clear();
    sessionStorage.clear();
    await supabaseClient.auth.signOut();
    dispatch(setIsLogged(false));
    window.location.href = "/";
  };
};

const useCreateNewChannel = (afterCreated = () => {}) => {
  const [isCreating, setIsCreating] = useState(false);

  const configsMainChat = useGetConfigsMainChat();
  const chatRepository = new ChatRepository();
  const dispatch = useDispatch();

  async function handleCreateChannel() {
    try {
      setIsCreating(true);
      const channel = {
        channelName: "New Channel",
        assistantId: configsMainChat?.assistant?._id || null,
      };

      const channelCreated = await createChannel(channel);

      dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.chatData, channelCreated));
      dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.typeChat, LIST_TYPE_CHAT[0]));

      const cachedChannels = queryClient.getQueryData(QUERY_KEYS.CHANNELS);

      if (cachedChannels) {
        queryClient.setQueryData(QUERY_KEYS.CHANNELS, [
          ...cachedChannels,
          channelCreated,
        ]);
      }

      await chatRepository.add({
        id: makeChatId(channelCreated._id, channelCreated.userId),
        messages: [],
      });

      return channelCreated;
    } catch (error) {
      showErrorNotification(error?.message || "Error creating chat");
      console.error(error);
    } finally {
      setIsCreating(false);
      afterCreated();
    }
  }

  return {
    handleCreateChannel,
    isCreating,
  };
};

const useDeleteChatData = chatId => {
  const db = new ChatRepository();

  async function handleDeleteChatData() {
    try {
      await db.delete(chatId);

      const cachedChannels = queryClient.getQueryData(QUERY_KEYS.CHANNELS);
      if (cachedChannels) {
        queryClient.setQueryData(
          QUERY_KEYS.CHANNELS,
          cachedChannels.filter(channel => channel._id !== chatId)
        );
      }
    } catch (error) {
      showErrorNotification(error?.message || "Error deleting chat data");
      console.error(error);
    }
  }

  return handleDeleteChatData;
};

export {
  useGetConfigsMainChat,
  useCreateNewChannel,
  useGetIsLogged,
  useLogout,
  useDeleteChatData,
};
