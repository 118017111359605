/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useUser } from "@supabase/auth-helpers-react";
import { likeCommunityAssistant, unlikeCommunityAssistant } from "../../api/assistants";
import { showErrorNotification } from "../../services/notifications";
import { IconHeart, IconHeartFilled, IconLoader2 } from "@tabler/icons-react";

export function LikeAssistants({ assistant, fontSizeNumberLikes }) {
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isUserLiked, setIsUserLiked] = useState(false);

  const user = useUser();

  const initialLikeCount = assistant?.likes || 0;
  const objectLike = {
    user_id: user?.id,
    flag_like: true,
    flag_dislike: false,
    id_assistant: assistant?._id || assistant?.objectID,
    timestamp: Date.now(),
    cont_likes: initialLikeCount + 1,
  };

  async function handleLikeContact() {
    if (isLikeLoading) return;
  
    try {
      setIsLikeLoading(true);
      setIsUserLiked(true);
      await likeCommunityAssistant(assistant?._id || assistant?.objectID);
  
      const existingLikes = JSON.parse(localStorage.getItem("likesArray")) || [];
      const index = existingLikes.findIndex(like =>
        like.user_id === user?.id && like.id_assistant === (assistant?._id || assistant?.objectID)
      );
  
      if (index === -1) {
        existingLikes.push({
          ...objectLike,
          flag_like: true,
          flag_dislike: false,
          cont_likes: initialLikeCount + 1,
        });
      } else {
        existingLikes[index].flag_like = true;
        existingLikes[index].flag_dislike = false;
        existingLikes[index].cont_likes += 1; 
      }
  
      localStorage.setItem("likesArray", JSON.stringify(existingLikes));
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsLikeLoading(false);
    }
  }
  

  async function handleUnlikeContact() {
    if (isLikeLoading) return;

    try {
      setIsLikeLoading(true);
      setIsUserLiked(false);
      await unlikeCommunityAssistant(assistant?._id || assistant?.objectID);

      const existingLikes = JSON.parse(localStorage.getItem("likesArray")) || [];
      const index = existingLikes.findIndex(like =>
        like.user_id === user?.id && like.id_assistant === (assistant?._id || assistant?.objectID)
      );

      if (index === -1) {
        existingLikes.push({
          ...objectLike,
          flag_like: false,
          flag_dislike: true,
          cont_likes: initialLikeCount > 0 ? initialLikeCount - 1 : 0,
        });
      } else {
        existingLikes[index].flag_like = false;
        existingLikes[index].flag_dislike = true;
        existingLikes[index].cont_likes = Math.max(existingLikes[index].cont_likes - 1, 0);
      }

      localStorage.setItem("likesArray", JSON.stringify(existingLikes));
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsLikeLoading(false);
    }
  }


  useEffect(() => {
    const existingLikes = JSON.parse(localStorage.getItem("likesArray")) || [];
    const isLikedInLocalStorage = existingLikes.some(like =>
      like.user_id === user?.id && like.id_assistant === (assistant?._id || assistant?.objectID) && like.flag_like
    );

    setIsUserLiked(isLikedInLocalStorage || (assistant?.likedByMe || assistant?.likedby?.includes(user?.id)));

    const now = Date.now();
    const expiryTime = 4800000;

    const updatedLikes = existingLikes.filter(like => (now - like.timestamp) < expiryTime);

    if (updatedLikes.length !== existingLikes.length) {
      localStorage.setItem("likesArray", JSON.stringify(updatedLikes));
    }
  }, [assistant, user?.id, initialLikeCount]);

  const getLikeCount = () => {
    const existingLikes = JSON.parse(localStorage.getItem("likesArray")) || [];
    const localCount = existingLikes.reduce((count, like) => {
      return like.id_assistant === (assistant?._id || assistant?.objectID) ? count + like.cont_likes : count;
    }, 0);

    return localCount || assistant?.likes || 0;
  };

  return (
    <div className="d-flex align-items-center gap-1">
      {isUserLiked ? (
        <button
          onClick={e => {
            e.stopPropagation();
            handleUnlikeContact();
          }}
          className="btn heartBeat m-0 p-0 d-flex align-items-center justify-content-center"
        >
          {isLikeLoading ? (
            <IconLoader2 className="spin sizeIconLike" stroke={1.5} />
          ) : (
            <IconHeartFilled stroke={1.5} color="red" className="sizeIconLike" />
          )}
        </button>
      ) : (
        <button
          onClick={e => {
            e.stopPropagation();
            handleLikeContact();
          }}
          className="btn btn-sm m-0 p-0 d-flex align-items-center justify-content-center"
        >
          {isLikeLoading ? (
            <IconLoader2 className="spin sizeIconLike" stroke={1.5} />
          ) : (
            <IconHeart stroke={1.5} className="sizeIconLike" />
          )}
        </button>
      )}

      <span style={{ fontSize: fontSizeNumberLikes }}>
        {getLikeCount()}
      </span>
    </div>
  );
}