import { IconChevronDown, IconPower } from "@tabler/icons-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/smallHooks";
import imgProfile from "../../assets/images/icons-new-layout/avatar-profile.svg";
import { formatPriceAiz } from "../../utils/helpers";
import useTranslation from "../../hooks/useTranslation";
import { PageChatI18n } from "../Add-ons/PageChat.i18n";
import useUserCredits from "../../hooks/useUserCredits";
import { UserSubscription } from "../Cards/UserSubscription";

const MenuNewLayout = ({ toggle, dropdownOpen, user }) => {
  const logout = useLogout();
  const navigate = useNavigate();

  const { t } = useTranslation(PageChatI18n);

  const avatarProfile = user?.user_metadata?.picture;

  const { credits, hasSubscriptionActive } = useUserCredits();
  const isActiveSubscription = hasSubscriptionActive();

  return (
    <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav className="box-account">
        {avatarProfile ? (
          <img
            src={avatarProfile}
            alt=""
            className="profile-user"
            style={{
              width: 24,
              height: 24,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <img src={imgProfile} alt="" />
        )}
        <span className={"one-line name-user"}>
          {user?.email?.split("@")[0]}
        </span>
        <IconChevronDown
          stroke={1}
          size={14}
          style={{
            transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-account mt-1 p-4 p-sm-3">
        <div className="d-flex justify-content-between align-items-center">
          <h4>{t("account")}</h4>
          <IconPower
            onClick={() => {
              logout();
              toggle(!dropdownOpen);
            }}
            stroke={1.5}
            style={{
              color: "red",
              marginLeft: 8,
              cursor: "pointer",
              width: 20,
              height: 20,
            }}
          />
        </div>
        <div className="d-flex flex-column gap-3 mt-sm-3 mt-4">
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => navigate("/settings?tab=credits")}
          >
            {isActiveSubscription ? (
              <UserSubscription />
            ) : (
              <div
                className="d-flex flex-column"
                style={{
                  gap: 12,
                }}
              >
                <strong className="m-0 p-0">{t("credits")}</strong>
                <div className="d-flex align-items-end">
                  <h3 className="my-0" style={{ marginRight: 12 }}>
                    ${formatPriceAiz(Number(credits?.amount || 0))}
                  </h3>
                </div>
              </div>
            )}
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <div>
              <i className={"bx bx-cog"} />
              {t("settings")}
            </div>
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => {
              navigate("/assistants");
            }}
          >
            <div>
              <i className={"bx bx-bot"} />
              {t("assistants")}
            </div>
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MenuNewLayout;
