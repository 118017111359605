import { useDispatch, useSelector } from "react-redux";
import { setWebModeLogin } from "../../../redux/general/action";
import COLORS from "../../../utils/constants/colors";

const SwitchWebMode = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const options = ["Web 2", "Web 3"];

  return (
    <div className="switch-web-mode">
      {options.map((option, index) => (
        <button
          key={index}
          style={{
            background:
              state?.configsGeneralReducer?.webModeLogin === option
                ? COLORS.white
                : "",
          }}
          onClick={() => dispatch(setWebModeLogin(option))}
        >
          <span>{option}</span>
        </button>
      ))}
    </div>
  );
};

export default SwitchWebMode;
