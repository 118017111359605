import { memo } from "react";
import { useChatStore } from "../../../../state/chat";
import Message from "./Message";
import { Virtuoso } from "react-virtuoso";

const Conversation = () => {
  const { messages } = useChatStore();

  return (
    <div className="chat-conversation h-100 pb-2">
      <Virtuoso
        style={{ height: "100%" }}
        totalCount={messages?.length || 0}
        initialTopMostItemIndex={messages?.length}
        itemContent={index => (
          <Message
            message={messages[index]}
            key={messages[index]?._id || messages[index]?.mId}
          />
        )}
        followOutput={true}
        increaseViewportBy={{ top: 1000, bottom: 4000 }}
      />
    </div>
  );
};

export default memo(Conversation);
