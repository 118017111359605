export const ModalSelectModelI18n = {
    "pt-BR": {
      title:"Selecionar modelo",
      tabs: {
        all:"Todos os modelos",
        chat:"Chat",
        free:"Grátis",
        code:"Código",
        cheaper:"Mais baratos",
      },
      details: {
        buttonDetails: "Detalhes",
        details: "Detalhes do modelo",
        name: "Nome",
        description: "Descrição",
        modality: "Modalidade",
        selectModel: "Selecionar modelo",
        back: "Voltar",
      },
      list: {
        token: "Preço por milhão de tokens",
        tokeWeb: "por milhão de tokens",
      }
    },
    "en-US": {
      title:"Select model",
      tabs: {
        all:"All models",
        chat:"Chat",
        free:"Free",
        code:"Code",
        cheaper:"Cheaper",
      },
      details: {
        buttonDetails: "Details",
        details: "Model details",
        name: "Name",
        description: "Description",
        modality: "Modality",
        selectModel: "Select model",
        back: "Back",
      },
      list: {
        token: "Pricing per million tokens",
        tokeWeb: "per million tokens",
      }
    },
    "es-ES": {
      title:"Seleccionar modelo",
      tabs: {
        all:"Todos los modelos",
        chat:"Chat",
        free:"Gratis",
        code:"Código",
        cheaper:"Mas barato",
      },
      details: {
        buttonDetails: "Detalles",
        details: "Detalles del modelo",
        name: "Nombre",
        description: "Descripción",
        modality: "Modalidad",
        selectModel: "Seleccionar modelo",
        back: "Volver",
      },
      list: {
        token: "Preci por milhón de tokens",
        tokeWeb: "por milhón de tokens",
      }
    },
  };