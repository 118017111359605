import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import { useQuery } from "react-query";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";
import {
  getAssistantImagesDefalut,
  getMyAssistants,
} from "../../../api/assistants";
import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { updateAssistantData } from "../../../redux/createAssistants/action";
import ModalImagesAvatar from "../../Modals/ModalImagesAvatar";
import ModalEditAssistant from "../../Modals/ModalEditAssistant";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";
import CardCreateAssistant from "../../Cards/CardCreateAssistant";
import { showRealoadNotification } from "../../../services/notifications";

const ListMyAssistants = ({ onClick }) => {
  let showError = false;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [modalEditImage, setModalEditImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [avatar, setAvatar] = useState("");

  const handleSelectGeneratedImage = url => {
    setAvatar(url);
  };
  const { data, isLoading, error } = useQuery(
    QUERY_KEYS.MY_ASSISTANTS,
    getMyAssistants,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const assistants = data?.assistants;

  const renderSkeletons = [...Array(6)].map((_, index) => (
    <Skeleton
      key={`skeleton-${index}`}
      baseColor={"#e4e4e7"}
      highlightColor={"#F1F1F1"}
      duration={1}
      height={132}
      borderRadius={16}
    />
  ));

  const handleGenerateImage = async () => {
    setModalEditImage(true);
    setLoadingImage(true);
    getAssistantImagesDefalut()
      .then(images => {
        setGeneratedImages(images);
        dispatch(updateAssistantData({ avatar: images[0] }));
      })
      .finally(() => setLoadingImage(false));
  };

  const handleAvatarChange = value => {
    dispatch(updateAssistantData({ avatar: value }));
  };

  useEffect(() => {
    if (selectedAssistant) {
      queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);
    }
  }, [selectedAssistant]);

  const handleCardClick = assistant => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  useEffect(() => {
    showRealoadNotification((error ? error.message : null), showError);
    showError = true;
 }, [error]);

  return (

    <>
      <div className="grid-horizontals-assistants">
        {isLoading
          ? renderSkeletons
          : assistants && assistants?.map((assistant, index) => (
              <CardAssistantsNewLayout
                setOpenModalInfo={() => handleCardClick(assistant)}
                key={index}
                direction="horizontal"
                image={assistant?.profileImage}
                title={assistant?.name}
                description={assistant?.role}
                icon={assistant?.profileImage}
                name={assistant?.userName}
                myAssistant={true}
                assistant={assistant}
                edit={() => {
                  setOpenModal(true);
                  setSelectedAssistant(assistant);
                }}
                onSelect={() => {
                  dispatch(
                    setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant)
                  );
                }}
              />
            ))}
      </div>
      {assistants?.length < 1 && (
        <CardCreateAssistant onClick={onClick}/>
      )}

      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => {
          setOpenModalInfo(false);
        }}
        assistant={selectedAssistant}
      />

      <ModalEditAssistant
        selectedAssistant={selectedAssistant}
        setOpenModal={setOpenModal}
        handleGenerateImage={handleGenerateImage}
        setModalEditImage={setModalEditImage}
        openModal={openModal}
      />

      <ModalImagesAvatar
        showModal={modalEditImage}
        generatedImages={generatedImages}
        loadingImage={loadingImage}
        onClick={() => {}}
        onSelectGeneratedImage={handleSelectGeneratedImage}
        closeModal={() => {
          setModalEditImage(false);
        }}
        handleAvatarChange={handleAvatarChange}
      />
    </>
  );
};

export default ListMyAssistants;
