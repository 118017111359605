export const NotificationI18n = {
  "pt-BR": {
    copyMessage: "Mensagem copiada",
    credits: {
      button: {
        buy: "Compre créditos",
      },
      noCredits:
        "Você não tem créditos suficientes para enviar uma mensagem, por favor compre créditos.",
      fewCredits:
        "A quantidade mínima de créditos para enviar uma mensagem é 1 dólar.",
      failedAiz: "Falha ao buscar a quantidade de token AIZ:",
      enterValueAiz: "Por favor, insira um valor para continuar com AIZ",
      onlyBaseNetwork:
        "No momento, estamos aceitando transações com token AIZ apenas na rede Base, redirecionando você para a rede correta agora",
      processingMetamask: "Processando a transação pelo Metamask...",
      addCredits: "Adicionando créditos à sua conta...",
      aizInsufficient:
        "Você não tem AIZ suficiente para completar a compra. Se quiser, você pode adquirir mais clicando aqui.",
      installMetamask:
        "Por favor, instale o MetaMask para continuar comprando créditos ou altere seu método de pagamento",
      addValuePix: "Por favor, insira um valor para continuar com Pix",
      addValueCard: "Por favor, insira um valor para continuar com Cartão",
      selectMethod: "Por favor, selecione um método de pagamento",
      addSuccessfully: "Créditos adicionados com sucesso",
      pixCodeCopied: "Código Pix copiado para a área de transferência",
      awaitingPayment: "Aguardando pagamento",
    },
  },
  "en-US": {
    copyMessage: "Message copied",
    credits: {
      button: {
        buy: "Buy credits",
      },
      noCredits:
        "You don't have enough credits to send a message, please buy credits.",
      fewCredits:
        "The minimum amount of credits to send a message is 1 dollar.",
      failedAiz: "Failed to fetch AIZ token amount:",
      enterValueAiz: "Please enter a value to continue with AIZ",
      onlyBaseNetwork:
        "We are currently only accepting AIZ token transactions on the Base Network, redirecting you to the correct network now",
      processingMetamask: "Processing the transaction from Metamask...",
      addCredits: "Adding credits to your account...",
      aizInsufficient:
        "You don't have enough AIZ to complete the purchase. If you want, you can acquire more by clicking here.",
      installMetamask:
        "Please install MetaMask to continue purchasing credits or change your payment method",
      addValuePix: "Please enter a value to continue with Pix",
      addValueCard: "Please enter a value to continue with Card",
      selectMethod: "Please select a payment method",
      addSuccessfully: "Credits added successfully",
      pixCodeCopied: "Pix code copied to clipboard",
      awaitingPayment: "Awaiting payment",
    },
  },
  "es-ES": {
    copyMessage: "Mensaje copiada",
    credits: {
      button: {
        buy: "Comprar créditos",
      },
      noCredits:
        "No tienes suficientes créditos para enviar un mensaje, por favor compra créditos.",
      fewCredits:
        "La cantidad mínima de créditos para enviar un mensaje es 1 dólar.",
      failedAiz: "Error al buscar la cantidad de token AIZ:",
      enterValueAiz: "Por favor, introduce un valor para continuar con AIZ",
      onlyBaseNetwork:
        "Actualmente, solo estamos aceptando transacciones con el token AIZ en la red Base, ahora te redireccionaremos a la red correcta",
      processingMetamask: "Procesando la transacción desde Metamask...",
      addCredits: "Agregando créditos a tu cuenta...",
      aizInsufficient:
        "No tienes suficiente AIZ para completar la compra. Si quieres, puedes adquirir más haciendo clic aquí.",
      installMetamask:
        "Por favor, instala MetaMask para continuar comprando créditos o cambia tu método de pago",
      addValuePix: "Por favor, introduce un valor para continuar con Pix",
      addValueCard: "Por favor, introduce un valor para continuar con Tarjeta",
      selectMethod: "Por favor, selecciona un método de pago",
      addSuccessfully: "Créditos añadidos con éxito",
      pixCodeCopied: "Código Pix copiado al portapapeles",
      awaitingPayment: "Esperando pago",
    },
  },
};