import { debounce } from "lodash";
import { memo, useEffect, useState } from "react";
import { Collapse, Input } from "reactstrap";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../services/notifications";
import useTranslation from "../../hooks/useTranslation";
import {
  createWebsiteEmbeddings,
  createYoutubeVideoEmbeddings,
} from "../../api/embeddings";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
} from "../../hooks/smallHooks";
import { IconLoader2 } from "@tabler/icons-react";
import { queryClient } from "../../libs/react-query";

import { AttachDocumentMenuI18n } from "./AttachDocumentMenu.i18n";
import COLORS from "../../utils/constants/colors";
import iconDownLeft from "../../assets/images/icons-new-layout/arrow-down-left.svg";

interface AttachDocumentMenuProps {
  isOpen: boolean;
  onToggle: () => void;
  onAttachDocumentByUrl: (url: string) => void;
  onAttachDocumentFile: (file: any) => void;
}

const AttachDocumentMenu = ({ isOpen, onToggle }: AttachDocumentMenuProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAttachByUrl, setShowAttachByUrl] = useState(false);
  const [url, setUrl] = useState<string>("");

  const { t } = useTranslation(AttachDocumentMenuI18n);

  const { chatData } = useGetConfigsMainChat() || {};

  const { handleCreateChannel } = useCreateNewChannel();

  const createChannelIfNecessary = async () => {
    if (!chatData) {
      return await handleCreateChannel();
    }
    return chatData;
  };

  async function handleSubimtUrl(url: string) {
    if (isLoading) return;

    const currentChannel = await createChannelIfNecessary();

    try {
      setIsLoading(true);
      const isYoutubeUrl = /https?:\/\/(?:www\.)?youtube\.com\/[^\s]+/gi.test(
        url
      );

      if (isYoutubeUrl) {
        await createYoutubeVideoEmbeddings({
          url,
          channelId: currentChannel._id,
        });

        await queryClient.invalidateQueries([
          "attachments",
          currentChannel._id,
        ]);

        showSuccessNotification(
          "Youtube video sent successfully! Initializing processing..."
        );

        onToggle();
        return;
      }

      const isValidUrl =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w \.-]*)*\/?\??([^#\n\r]*)?#?([^#\n\r]*)?$/.test(
          url
        );

      if (!isValidUrl) {
        return showErrorNotification("Invalid URL");
      }

      await createWebsiteEmbeddings({
        url: url,
        channelId: currentChannel._id,
      });

      await queryClient.invalidateQueries(["attachments", currentChannel._id]);

      showSuccessNotification(
        "Website sent successfully! Initializing processing..."
      );

      onToggle();
    } catch (error: any) {
      showErrorNotification(error.message || "Error when sending content");
    } finally {
      setIsLoading(false);
    }
  }

  const AttachByURL = () => (
    <div className="w-100">
      <div className="d-flex align-items-center gap-3 w-100">
        <Input
          style={{ border: "none", padding: 0, height: 36, fontSize: 12 }}
          type="text"
          placeholder={t("placeholder.main")}
          autoFocus
          defaultValue={url}
          onChange={debounce((e: any) => setUrl(e.target.value), 700)}
        />
      </div>
    </div>
  );

  const SliderButtonOptions = () => {
    return (
      <div className="d-flex flex-column align-items-center gap-2">
        {!isLoading ? (
          <>
            <div
              className="w-100 d-flex  align-items-center"
              style={{
                border: `1px solid ${COLORS.gray300}`,
                padding: "0px 12px",
                borderRadius: 10,
              }}
            >
              <AttachByURL />
              {url && (
                <button
                  color="primary"
                  type="button"
                  className="p-0 d-flex align-items-center justify-content-center"
                  disabled={!url.trim() || isLoading}
                  onClick={() => {
                    handleSubimtUrl(url.trim());
                    setUrl("");
                  }}
                >
                  {isLoading ? (
                    <IconLoader2 className="spin" />
                  ) : (
                    <img
                      src={iconDownLeft}
                      alt=""
                      style={{
                        backgroundColor: COLORS.gray700,
                        padding: 4,
                        borderRadius: 6,
                      }}
                      width={20}
                      height={20}
                    />
                  )}
                </button>
              )}
            </div>
          </>
        ) : (
          <IconLoader2 className="spin" />
        )}
      </div>
    );
  };

  useEffect(() => {
    setUrl("");
  }, [isOpen]);
  return (
    <Collapse
      isOpen={isOpen}
      className="chat-input-collapse"
      id="chatinputmorecollapse"
    >
      {isOpen && (
        <div className="d-flex flex-column">
          <div>
            {!showAttachByUrl ? <SliderButtonOptions /> : <AttachByURL />}
          </div>
        </div>
      )}
    </Collapse>
  );
};

export default memo(AttachDocumentMenu);
