import React, { useState } from "react";

const Tooltip = ({ children, text, action }) => {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);

  const showTooltip = () => {
    setVisible(true);
    setTimeout(() => {
      setShow(true);
    }, 300);
  };

  const hideTooltip = () => {
    setShow(false);
    setTimeout(() => {
      setVisible(false);
    }, 300);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div
          className={`tooltip-box ${
            show ? "show" : ""
          } d-flex align-items-center gap-2`}
        >
          {text}
          {action && (
            <button
              type="button"
              className="text-primary rounded-1 p-1 small fw-semibold hover-tooltip-button"
              onClick={() => {
                action.execute();
                hideTooltip();
              }}
            >
              {action.name}
            </button>
          )}
          <div className="tooltip-arrow" />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
